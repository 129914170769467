

/*--------------------- CLASSE PER OTTENERE I DATI DI UN CARD ---------------------*/
class GetDashCard {
    constructor(json) {
        this.id_piano = json.id_piano;
        this.residente = json.residente;
        this.id_residente = json.id;
        this.percorso = json.percorso;
        this.id_stanza = json.id_stanza;
        this.sensori = json.sensori;
        this.sensori_ambiente = json.sensori_ambiente;
    }
}


export { GetDashCard };


