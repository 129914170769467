import React, { useEffect, useRef, useState } from "react";

import userB from "../imgs/icons/userB.svg";
import arrowPurple from "../imgs/icons/arrowPurple.svg";
import positionPurple from "../imgs/icons/positionPurple.svg";
import alarmR from "../imgs/icons/alarmR.svg";
import alarmY from "../imgs/icons/alarmY.svg";
import offline from "../imgs/icons/offline.svg";
import confirmW from "../imgs/icons/confirmW.svg";
import close from "../imgs/icons/close.svg";
import caduta from "../imgs/icons/caduta.svg";
import residentiB from "../imgs/icons/residentiB.svg";
import bedB from "../imgs/icons/bedB.svg";

import residentiNode from "node/residenti/residenti.js";
import sensoreNode from "node/sensori/sensori.js";
import stanzeNode from "node/stanze/stanze.js";

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].dashboard_residente;

const DashboardResidente = (props) => {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const scrollRef = useRef(null);
    const [nota, setNota] = useState();
    const status = props.status;

    let sensoreLetto = props.info.sensori.find((item) => item.tipo === "Sensore letto");
    if (sensoreLetto === undefined) { sensoreLetto = { tipo: "Sensore letto", stato: "Offline", banner: -1 }; }

    let sensoreGeofencing = props.info.sensori.find((item) => item.tipo === "Sensore geofencing");
    if (sensoreGeofencing === undefined) { sensoreGeofencing = { tipo: "Sensore geofencing", stato: "Offline", banner: -1 }; }

    let sensoreCaduta = props.info.sensori.find((item) => item.tipo === "Sensore caduta");
    if (sensoreCaduta === undefined) { sensoreCaduta = { tipo: "Sensore caduta", stato: "Offline", banner: -1 }; }

    let sensoreTemperatura = props.info.sensori.find((item) => item.tipo === "Sensore temperatura");
    if (sensoreTemperatura === undefined) { sensoreTemperatura = { tipo: "Sensore temperatura", stato: "Offline", banner: -1 }; }

    async function getResidente() {
        props.loading(true);
        await residentiNode.getResidenti(accountData.token).then((response) => {
            props.SchedaInScheda('scheda_residente', response.data.filter((residente) => residente.id === props.info.id)[0], 'ritorna', props.info, 'residente');
        }).finally(() => {
            props.loading(false);
        });
    }

    function scroll(dir) {
        const item = scrollRef.current;
        const distance = 150;
        const duration = 350;
        const increment = distance / duration * 10;
        let counter = 0;
        let intervalId;
        if (dir === "left") {
            intervalId = setInterval(() => {
                item.scrollBy(-increment, 0);
                counter += 10;
                if (counter >= duration) clearInterval(intervalId);
            }, 10);
        } else {
            intervalId = setInterval(() => {
                item.scrollBy(increment, 0);
                counter += 10;
                if (counter >= duration) clearInterval(intervalId);
            }, 10);
        }
    }

    useEffect(() => {
        const item = scrollRef.current;
        item.addEventListener("wheel", function (e) {
            if (e.deltaY > 0) item.scrollLeft += 20;
            else item.scrollLeft -= 20;
        });
    }, []);

    async function getResidente(id) {
        props.loading(true);
        await residentiNode.getResidenti(accountData.token).then((response) => {
            props.SchedaInScheda('scheda_residente', response.data.filter((residente) => residente.id === id)[0], 'ritorna_dashboard', props.info);
        }).finally(() => {
            props.loading(false);
        });
    }

    async function getSensore(id) {
        props.loading(true);
        await sensoreNode.getSensori(accountData.token).then((response) => {
            props.SchedaInScheda('scheda_sensore', response.data.filter((sensore) => sensore.id === id)[0], 'ritorna_dashboard', props.info);
        }).finally(() => {
            props.loading(false);
        });
    }

    async function getStanza(id) {
        props.loading(true);
        await stanzeNode.getStanza(accountData.token, id).then((response) => {
            props.SchedaInScheda('scheda_stanza', response.data, 'ritorna_dashboard', props.info);
        }).finally(() => {
            props.loading(false);
        });
    }

    return (
        <>
            <div id="PopupBody" style={{ display: status === 0 ? "" : "none" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "4em" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={userB} alt="userB" style={{ margin: "1em", width: "3em" }} />
                        <p style={{ fontSize: "200%" }}>
                            {props.info.nome}
                        </p>
                    </div>
                    <div className="selettore selettoreFlex selettoreBorder" style={{ margin: "1em" }} onClick={() => getResidente(props.info.id)}>
                        <p>{langSet.schedaResidente}</p>
                        <img src={arrowPurple} alt="arrow" style={{ transform: "rotate(-90deg)" }} />
                    </div>
                </div>
                <div className="popupCardContainer" ref={scrollRef}>
                    <div className="popupCard">
                        <table className="scheda noBorder" style={{ width: "40em" }}>
                            <tbody>
                                <tr>
                                    <td colSpan={2} style={{ fontWeight: "200", padding: 0 }}>
                                        <img src={caduta} alt="caduta" style={{ width: "1.5em" }} />&nbsp;{langSet.monitoraggioCaduta}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ height: "4.5em", backgroundColor: sensoreCaduta.banner <= 0 ? "#F8F9FD" : "#FBE6E5", position: "relative" }}>
                                        &ensp;{sensoreCaduta.stato === "Monitoraggio..." ? langSet.monitoraggio : sensoreCaduta.stato === "Caduta rilevata!" ? langSet.cadutaRilevata : "Offline"}
                                        <div className="right">
                                            {sensoreCaduta.banner === -1 ? <img src={offline} alt="alrm" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} /> : sensoreCaduta.banner === 1 ? <img src={alarmY} alt="alrm" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} /> : sensoreCaduta.banner === 2 ? <img src={alarmR} alt="alrm" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} /> : <div className='dot green big' style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }}>&nbsp;</div>}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "0 1.5em" }}>
                                        <label>{langSet.tipoDiAllarme}</label>
                                        <p>{sensoreCaduta.tipo}</p>
                                    </td>
                                    <td style={{ padding: "0 1.5em" }}>
                                        <label>{langSet.creatoIl}</label>
                                        <p>-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ padding: "0 1.5em" }}>
                                        <div style={{ float: "left" }}>
                                            <label>{langSet.dispositivo}</label>
                                            <p>{sensoreCaduta.nome}</p>
                                        </div>
                                        <div className={(sensoreCaduta.id === undefined ? "disabled" : "") + " selettore selettoreBorder selettoreFlex mini-selettore"} style={{ float: "right", maxWidth: "8em", top: "1.5em" }} onClick={sensoreCaduta.id === undefined ? null : () => getSensore(sensoreCaduta.id)}>
                                            <p>{langSet.schedaSensore}</p>
                                            <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(-90deg)" }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "200", padding: "0 1.5em" }}>
                                        <br />
                                        {langSet.note}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ height: "4.5em", border: "1px solid #f5f5f5" }}>
                                        <textarea
                                            value={nota}
                                            onChange={(e) => setNota(e.target.value)}
                                            style={{ width: "100%", height: "100%", border: "none", resize: "none", padding: ".5em" }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="popupCard">
                        <table className="scheda noBorder" style={{ width: "40em" }}>
                            <tbody>
                                <tr>
                                    <td colSpan={2} style={{ fontWeight: "200", padding: 0 }}>
                                        <img src={positionPurple} alt="positionPurple" style={{ width: "1.5em" }} />&nbsp;{langSet.posizioneDelResidente}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ height: "4.5em", backgroundColor: sensoreGeofencing.banner <= 0 ? "#F8F9FD" : "#FBE6E5", position: "relative" }}>
                                        &ensp;{sensoreGeofencing.stato === "Posizione sconosciuta" ? langSet.posizioneSconosciuta :
                                            sensoreGeofencing.stato === "In Corridoio" ? langSet.inCorridoio :
                                                sensoreGeofencing.stato === "In Camera" ? langSet.inCamera :
                                                    sensoreGeofencing.stato === "In guardiola" ? langSet.inGuardiola : sensoreGeofencing.stato}
                                        <div className="right">
                                            {sensoreGeofencing.banner === -1 ? <img src={offline} alt="alrm" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} /> : sensoreCaduta.banner === 1 ? <img src={alarmY} alt="alrm" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} /> : sensoreGeofencing.banner === 2 ? <img src={alarmR} alt="alrm" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} /> : <div className='dot green big' style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }}>&nbsp;</div>}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "0 1.5em" }}>
                                        <label>{langSet.tipoDiAllarme}</label>
                                        <p>{sensoreGeofencing.tipo}</p>
                                    </td>
                                    <td style={{ padding: "0 1.5em" }}>
                                        <label>{langSet.creatoIl}</label>
                                        <p>-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ padding: "0 1.5em" }}>
                                        <div style={{ float: "left" }}>
                                            <label>{langSet.dispositivo}</label>
                                            <p>{sensoreGeofencing.nome}</p>
                                        </div>
                                        <div className={(sensoreGeofencing.id === undefined ? "disabled" : "") + " selettore selettoreBorder selettoreFlex mini-selettore"} style={{ float: "right", maxWidth: "8em", top: "1.5em" }} onClick={sensoreGeofencing.id === undefined ? null : () => getSensore(sensoreGeofencing.id)}>
                                            <p>{langSet.schedaSensore}</p>
                                            <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(-90deg)" }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "200", padding: "0 1.5em" }}>
                                        <br />
                                        {langSet.note}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ height: "4.5em", border: "1px solid #f5f5f5" }}>
                                        <textarea
                                            value={nota}
                                            onChange={(e) => setNota(e.target.value)}
                                            style={{ width: "100%", height: "100%", border: "none", resize: "none", padding: ".5em" }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="popupNavigator">
                    <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(90deg)" }} onClick={() => scroll("left")} />
                    <p>&emsp;-&emsp;</p>
                    <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(-90deg)" }} onClick={() => scroll("right")} />
                </div>
                <div className="popupButtons">
                    <div className="selettore selettoreBlue selettoreFlex">
                        <p>{langSet.salvaERisolvi}</p>
                        <img src={confirmW} alt="confirm" />
                    </div>
                    <div className="selettore selettoreViolet selettoreFlex" onClick={() => props.closePopup()}>
                        <p>{langSet.annulla}</p>
                        <img src={close} alt="close" />
                    </div>
                </div>
            </div>
            <div id="PopupBody" style={{ display: status === 1 ? "" : "none" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "4em" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={residentiB} alt="residenti" style={{ margin: "1em", width: "3em" }} />
                        <div style={{ lineHeight: 0 }}>
                            <p>{props.info.percorso}</p>
                            <p style={{ fontSize: "200%" }}>
                                {props.info.id_stanza}
                            </p>
                        </div>
                    </div>
                    <div className="selettore selettoreFlex selettoreBorder" style={{ margin: "1em" }} onClick={() => getStanza(props.info.id_stanza)}>
                        <p>{langSet.schedaStanza}</p>
                        <img src={arrowPurple} alt="arrow" style={{ transform: "rotate(-90deg)" }} />
                    </div>
                </div>
                <div className="popupCardContainer" ref={scrollRef}>
                    <div className="popupCard">
                        <table className="scheda noBorder" style={{ minWidth: "25em" }}>
                            <tbody>
                                <tr>
                                    <td colSpan={2} style={{ fontWeight: "200", padding: 0 }}>
                                        <img src={bedB} alt="bedB" style={{ width: "1.5em" }} />&nbsp;{langSet.statoDelLetto}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ height: "4.5em", backgroundColor: sensoreLetto.banner <= 0 ? "#F8F9FD" : "#FBE6E5", position: "relative" }}>
                                    &ensp;{sensoreLetto.stato === "Assente" ? langSet.assente : sensoreLetto.stato === "Presente" ? langSet.presente : "Offline"}
                                        <div className="right">
                                            {sensoreLetto.banner === -1 ? <img src={offline} alt="alrm" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} /> : sensoreLetto.banner === 1 ? <img src={alarmY} alt="alrm" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} /> : sensoreLetto.banner === 2 ? <img src={alarmR} alt="alrm" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} /> : <div className='dot green big' style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }}>&nbsp;</div>}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "0 1.5em" }}>
                                        <label>{langSet.tipoDiAllarme}</label>
                                        <p>{sensoreLetto.tipo}</p>
                                    </td>
                                    <td style={{ padding: "0 1.5em" }}>
                                        <label>{langSet.creatoIl}</label>
                                        <p>-</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ padding: "0 1.5em" }}>
                                        <div style={{ float: "left" }}>
                                            <label>{langSet.dispositivo}</label>
                                            <p>{sensoreLetto.nome}</p>
                                        </div>
                                        <div className={(sensoreLetto.id === undefined ? "disabled" : "") + " selettore selettoreBorder selettoreFlex mini-selettore"} style={{ float: "right", maxWidth: "8em", top: "1.5em" }} onClick={sensoreLetto.id === undefined ? null : () => getSensore(sensoreLetto.id)}>
                                            <p>{langSet.schedaSensore}</p>
                                            <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(-90deg)" }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "200", padding: "0 1.5em" }}>
                                        <br />
                                        {langSet.note}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ height: "4.5em", border: "1px solid #f5f5f5" }}>
                                        <textarea
                                            value={nota}
                                            onChange={(e) => setNota(e.target.value)}
                                            style={{ width: "100%", height: "100%", border: "none", resize: "none", padding: ".5em" }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="popupNavigator">
                    <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(90deg)" }} onClick={() => scroll("left")} />
                    <p>&emsp;-&emsp;</p>
                    <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(-90deg)" }} onClick={() => scroll("right")} />
                </div>
                <div className="popupButtons">
                    <div className="selettore selettoreBlue selettoreFlex">
                        <p>{langSet.salvaERisolvi}</p>
                        <img src={confirmW} alt="confirm" />
                    </div>
                    <div className="selettore selettoreViolet selettoreFlex" onClick={() => props.closePopup()}>
                        <p>{langSet.annulla}</p>
                        <img src={close} alt="close" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardResidente;