

class AddPiano {
    constructor(id, descrizione){
        this.id = id;
        this.descrizione = descrizione;
    }
    toJson(){
        return {
            id: this.id,
            descrizione: this.descrizione
        }
    }
}

export { AddPiano };