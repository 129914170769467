import React, { useState } from "react";
import { createRoot } from "react-dom/client";

import "./styles/style.css";

import logo from "./imgs/tekcare_orizz_color@4x.png";
import eye from "./imgs/icons/eye.svg";
import eyeNo from "./imgs/icons/eyeNo.svg";

import Home from "./pages/home.js";

import auth from "node/auth/auth.js";

const root = createRoot(document.getElementById("root"));

function loading(bool) {
  bool ?
    document.getElementById("loading").style.display = "block"
    :
    document.getElementById("loading").style.display = "none";
}

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();

  async function handleSubmit(event) {
    event.preventDefault();
    loading(true);
    let account = await auth.signIn(email, password);
    console.log(account);

    if (account.result) {
      setError(undefined);
      //if (document.getElementById("remember").checked) {
      localStorage.setItem("login", true);
      /* } else {
        localStorage.setItem("login", "true-no-remember");
      } */
      localStorage.setItem("account", JSON.stringify(account.data));
      localStorage.setItem("struttura", account.data.db);
      window.location.reload();
    } else {
      setError(account.error);
      loading(false);
    }
  }

  const passEye = () => {
    if (document.getElementById("password").type === "password") {
      document.getElementById("password").type = "text";
      document.getElementById("eye").src = eyeNo;
    } else {
      document.getElementById("password").type = "password";
      document.getElementById("eye").src = eye;
    }
  }

  const body = (
    <>
      <div id="loading" />
      <div id="Background">
        <div className="card">
          <div className="top_card">
            <img src={logo} className="App-logo" alt="CARE4U 2.0" />
          </div>
          <div className="middle_card">
            <form id="form" onSubmit={handleSubmit}>
              <div>
                <label>Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Inserisci la tua email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  style={{ textTransform: "lowercase", minWidth: "20em", margin: "1em" }}
                  required
                />
              </div>
              <div >
                <label>Password</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Inserisci la tua password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  style={{ minWidth: "20em", margin: "1em" }}
                  required
                />
                <img src={eye} className="pass_eye" id="eye" onClick={passEye} alt="eye" />
              </div>
              <div style={{ height: ".5em", width: "20em", margin: "1em", /* opacity: 0 */ }}>
                {/* <label>Rimani loggato</label>
                <input type="checkbox" id="remember" defaultChecked/> */}
                {error ? <p className="error">{error}</p> : <></>}
              </div>
              <input type="submit" value="Login" style={{ position: "relative", left: "1em" }} />
            </form>
          </div>
          <div className="bottom_card">
            <p>© 2023 - TekCare</p>
          </div>
        </div>
      </div>
    </>
  );

  return body;
}

if (
  localStorage.getItem("login") === "false" ||
  !localStorage.getItem("login")
) {
  root.render(<Login />);
} else {
  root.render(<Home />);
}
