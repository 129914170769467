import React, { useState, useRef, useEffect } from "react";

import close from "../imgs/icons/close.svg";
import confirmW from '../imgs/icons/confirmW.svg';
import arrowPurple from '../imgs/icons/arrowPurple.svg';
import positionPurple from '../imgs/icons/positionPurple.svg';
import alarmR from "../imgs/icons/alarmR.svg";

import notificheNode from "node/notifiche/notifiche.js";
import sensoreNode from "node/sensori/sensori.js";
import residentiNode from "node/residenti/residenti.js";
import stanzeNode from "node/stanze/stanze.js";

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_notifica;

const SchedaNotifica = (props) => {
    const defSelettore = "selettore selettoreBorder selettoreFlex mini-selettore";
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [nota, setNota] = useState('');
    const scrollRef = useRef(null);

    function resetData() {
        setNota('');
    }

    function scroll(dir) {
        const item = scrollRef.current;
        const distance = 150;
        const duration = 350;
        const increment = distance / duration * 10;
        let counter = 0;
        let intervalId;
        if (dir === "left") {
            intervalId = setInterval(() => {
                item.scrollBy(-increment, 0);
                counter += 10;
                if (counter >= duration) clearInterval(intervalId);
            }, 10);
        } else {
            intervalId = setInterval(() => {
                item.scrollBy(increment, 0);
                counter += 10;
                if (counter >= duration) clearInterval(intervalId);
            }, 10);
        }
    }


    useEffect(() => {
        const item = scrollRef.current;
        item.addEventListener("wheel", function (e) {
            if (e.deltaY > 0) item.scrollLeft += 20;
            else item.scrollLeft -= 20;
        });
        if (item.scrollWidth > item.clientWidth) {
            document.getElementsByClassName("popupNavigator")[0].style.display = "flex";
        } else {
            document.getElementsByClassName("popupNavigator")[0].style.display = "none";
        }
    }, []);

    async function confermaNotifica() {
        props.loading(true);
        if (!props.notifica.confermata) {
            let responseConferma = await notificheNode.patchStatoNotifica(accountData.token, props.notifica.id);
            if (!responseConferma.result) props.SchedaInScheda('alarm', responseConferma.error.toString(), 'ritorna_notifica', props.notifica)
        }

        if (nota && nota !== '') {
            let responseNota = await notificheNode.putNotaNotifica(accountData.token, props.notifica.id, nota);
            if (!responseNota.result) props.SchedaInScheda('alarm', responseNota.error.toString(), 'ritorna_notifica', props.notifica);
        }
        await props.getNot();
        props.loading(false);
        props.closePopup();
    }
    
    async function getStanza(id) {
        await stanzeNode.getStanza(accountData.token, id).then((response) => {
            if (!response.result) throw response.error;
            props.SchedaInScheda('scheda_stanza', response.data, 'ritorna_notifica', props.notifica);
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_notifica', props.notifica));
    }

    async function getResidente(id) {
        props.loading(true);
        await residentiNode.getResidente(accountData.token, id).then((response) => {
            if (!response.result) throw response.error;
            props.SchedaInScheda('scheda_residente', response.data, 'ritorna_notifica', props.notifica);
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_notifica', props.notifica)).finally(() => {
            props.loading(false);
        });
    }

    async function getSensore(id) {
        props.loading(true);
        await sensoreNode.getSensore(accountData.token, id).then((response) => {
            if(!response.result) throw response.error;
            props.SchedaInScheda('scheda_sensore', response.data, 'ritorna_notifica', props.notifica);
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_notifica', props.notifica)).finally(() => {
            props.loading(false);
        });
    }

    return (
        <>
            <div id="PopupBody">
                <div className="popupCardContainer" ref={scrollRef}>
                    <div className="popupCard">
                        <table className="scheda noBorder" style={{ width: "40em" }}>
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: "200", padding: "0 1.5em" }}>
                                        <img src={positionPurple} alt="positionPurple" style={{ width: "1.5em" }} />&nbsp;{langSet.posizioneDelResidente}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ height: "8.5em", backgroundColor: props.notifica.confermata ? "#F8F9FD" : "#FBE6E5", position: "relative" }}>
                                        {props.notifica.messaggio}
                                        <img src={alarmR} alt="alarmR" style={{ width: "4em", position: "absolute", right: ".5em", top: ".5em" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: "200", padding: "0 1.5em" }}>
                                        <br />
                                        {langSet.note}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ height: "8.5em", border: "1px solid #f5f5f5" }}>
                                        {!props.notifica.confermata ?
                                            <>
                                                <textarea
                                                    value={nota}
                                                    onChange={(e) => setNota(e.target.value)}
                                                    style={{ width: "100%", height: "100%", border: "none", resize: "none", padding: ".5em" }}
                                                />
                                            </>
                                            :
                                            <>
                                                {props.notifica.confermata && props.notifica.note ? props.notifica.note :
                                                    <>
                                                        {
                                                            props.notifica.nomeutente === accountData.email ?
                                                                <textarea
                                                                    value={nota}
                                                                    onChange={(e) => setNota(e.target.value)}
                                                                    style={{ width: "100%", height: "100%", border: "none", resize: "none", padding: ".5em" }}
                                                                />
                                                                : props.notifica.note
                                                        }
                                                    </>
                                                }
                                            </>
                                        }

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="popupCard">
                        <table className="scheda" style={{ width: "40em" }}>
                            <tbody>
                                <tr>
                                    <td colSpan={6} style={{ padding: ".5em" }}>
                                        <label>{langSet.tipo}</label>
                                        <p>{props.notifica.tipo}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3} style={{ padding: ".5em", width: "17.5em" }}>
                                        <label>{langSet.stanza}</label>
                                        <p>{props.notifica.id_stanza}</p>
                                        <div className={props.notifica.id_stanza ? defSelettore : defSelettore + " disabled"} style={{ float: "left", maxWidth: "8em" }} onClick={() => getStanza(props.notifica.id_stanza)}>
                                            <p>{langSet.schedaStanza}</p>
                                            <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(-90deg)" }} />
                                        </div>
                                    </td>
                                    <td colSpan={3} style={{ padding: ".5em", width: "17.5em" }}>
                                        <label>{langSet.residente}</label>
                                        <p>{props.notifica.residente ? props.notifica.residente : " - "}</p>
                                        <div className={props.notifica.residente ? defSelettore : defSelettore + " disabled"} style={{ float: "left", maxWidth: "8em" }} onClick={() => getResidente(props.notifica.id_residente)}>
                                            <p>{langSet.schedaResidente}</p>
                                            <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(-90deg)" }} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ padding: ".5em" }}>
                                        <label>{langSet.creatoIl}</label>
                                        <p>{props.notifica.creato}</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: ".5em", display: props.notifica.confermata ? "" : "none" }}>
                                        <label>{langSet.risoltoIl}</label>
                                        <p>{props.notifica.timestamp}</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: ".5em", display: props.notifica.confermata ? "" : "none" }}>
                                        <label>{langSet.operatore}</label>
                                        <p>{props.notifica.nomeutente}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6} style={{ padding: ".5em" }}>
                                        <label>{langSet.dispositivo}</label>
                                        <p>{props.notifica.id_sensore}</p>
                                        <div className="selettore selettoreBorder selettoreFlex mini-selettore" style={{ float: "left", maxWidth: "8em" }} onClick={() => getSensore(props.notifica.id_sensore)}>
                                            <p>{langSet.schedaSensore}</p>
                                            <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(-90deg)" }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="popupNavigator">
                    <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(90deg)" }} onClick={() => scroll("left")} />
                    <p>&emsp;-&emsp;</p>
                    <img src={arrowPurple} alt="arrowPurple" style={{ transform: "rotate(-90deg)" }} onClick={() => scroll("right")} />
                </div>
                <div className="popupButtons">
                    <div className="selettore selettoreBlue selettoreFlex" onClick={() => confermaNotifica()} style={{ display: !props.notifica.confermata ? "" : props.notifica.confermata && props.notifica.note === undefined && props.notifica.nomeutente === accountData.email ? "" : "none" }}>
                        <p>{langSet.salvaERisolvi}</p>
                        <img src={confirmW} alt="confirm" />
                    </div>
                    <div className="selettore selettoreViolet selettoreFlex" onClick={() => { resetData(); props.closePopup(); }} style={{ display: !props.notifica.confermata ? "" : props.notifica.confermata && props.notifica.note === undefined && props.notifica.nomeutente === accountData.email ? "" : "none" }}>
                        <p>{langSet.annulla}</p>
                        <img src={close} alt="close" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SchedaNotifica;