import axios from 'axios';
import * as Crypto from '../crypt/crypt.js';
import * as Allarmi from './allarmi_model.js';
import * as Auth from '../auth/auth.js';



/*--------------------- OTTENGO GLI ALLARMI DEL SENSORE ---------------------*/
async function getAllarmi(token, id_sensore, sort, orderby) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/allarmi/${id_sensore}`, { params: { sortby: sort, orderby: orderby }, headers: headers });

    let allarmi = JSON.parse(Crypto.decrypt(response.data));

    let data = [];

    for (let allarme of allarmi) {
      data.push(new Allarmi.GetAllarme(allarme));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function getAllarmiLetto(token, id_sensore) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/allarmi/sensore_letto/${id_sensore}`, { headers });

    let allarmi = JSON.parse(Crypto.decrypt(response.data));

    let data = [];

    for (let allarme of allarmi) {
      data.push(new Allarmi.GetAllarmeLetto(allarme));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function getAllarmiCaduta(token, id_sensore) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/allarmi/sensore_caduta/${id_sensore}`, { headers });

    let allarmi = JSON.parse(Crypto.decrypt(response.data));

    let data = [];

    for (let allarme of allarmi) {
      data.push(new Allarmi.GetAllarmeCaduta(allarme));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function getStandardAllarmi(token, path) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/allarmi/standard/${path}`, { headers: headers });

    let allarmi = JSON.parse(Crypto.decrypt(response.data));

    console.log(allarmi);

    let data = [];

    for (let allarme of allarmi) {
      data.push(new Allarmi.StandardAllarmi(allarme));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- AGGIUNGO ALLARME ---------------------*/
async function addAllarme(token, allarmi) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    console.log(`ALLARMI NODEE: ${JSON.stringify(allarmi)}`);

    let postAllarmi = [];

    for (let allarme of allarmi) {
      console.log(`ALLARME: ${JSON.stringify(allarme)}`);
      let id_sensore = allarme.id_sensore;

      delete allarme.id_sensore;

      const { giorni, inizio, fine, descrizione, abilitato, id_piani, messaggio, tipo, lingua, valore, ritardo } = allarme;

      console.log(`RITARDO: ${ritardo}`);

      const postAllarmiSensore = new Allarmi.PostAllarmiSensore(id_sensore, new Allarmi.Allarme(giorni, inizio, fine, descrizione, abilitato, id_piani, messaggio, tipo, lingua, valore, ritardo).toJson());

      postAllarmi.push(postAllarmiSensore.toJson());
    }

    console.log(postAllarmi);

    const response = await axios.post(`${Auth.default.url}/allarmi`, Crypto.encrypt(JSON.stringify(postAllarmi)), { headers: headers });

    /* const postData = allarmi.map((allarme) => {
      const { id_sensore, giorni, inizio, fine, descrizione, abilitato, id_piani, messaggio, tipo, lingua, valore } = allarme;
      const postAllarmiSensore = new Allarmi.PostAllarmiSensore(id_sensore, new Allarmi.Allarme(giorni, inizio, fine, descrizione, abilitato, id_piani, messaggio, tipo, lingua, valore).toJson());

      return postAllarmiSensore.toJson();
    }); */



    return { result: true, message: Crypto.decrypt(response.data) };

  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data.toString()) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function deleteAllarme(token, id_allarme) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    const response = await axios.delete(`${Auth.default.url}/allarmi/${id_allarme}`, { headers: headers });

    return { result: true, message: Crypto.decrypt(response.data) };

  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- ATTIVO/DISATTIVO GLI ALLARMI PER QUEL SENSORE ---------------------*/
async function patchStatoAllarme(token, id_allarme, stato) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    const response = await axios.patch(`${Auth.default.url}/allarmi/${id_allarme}/${stato}`, {}, { headers: headers });

    return { result: true, message: Crypto.decrypt(response.data) };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function editAllarme(token, allarme) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    let data = Crypto.encrypt(JSON.stringify(allarme.toJson()));

    const response = await axios.patch(`${Auth.default.url}/allarmi/${allarme.id}`, data, { headers: headers });

    return { result: true, message: Crypto.decrypt(response.data) };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

/*--------------------- AGGIUNGO PROFILO ---------------------*/
async function caricaProfilo(token, id_profilo, id_sensore) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const response = await axios.post(`${Auth.default.url}/allarmi/load/${id_profilo}/${id_sensore}`, {}, { headers: headers });

    return { result: true, message: Crypto.decrypt(response.data) };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

export default { getAllarmi, getAllarmiLetto, getAllarmiCaduta, getStandardAllarmi, addAllarme, deleteAllarme, patchStatoAllarme, editAllarme, caricaProfilo };
