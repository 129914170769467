import React, { useState, useEffect } from "react";

import bedB from '../imgs/icons/bedB.svg';

import strutturaNode from 'node/struttura/struttura.js';

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_stanza;

const SchedaStanza = (props) => {
    const [mod, setMod] = useState(0);
    const [status, setStatus] = useState(0);
    const [stanza, setStanza] = useState(props.stanza);
    const [letti, setLetti] = useState([]);
    const accountData = JSON.parse(localStorage.getItem('account'));

    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow";
    const defContinua = "selettore selettoreFlex selettoreBlue";

    async function createLetti() {
        setLetti([]);
        for (let i = 0; i < props.stanza.num_letti - 1; i++) {
            if (props.stanza.id_residenti.length < i) {
                setLetti(current => [...current, { id: i, stato: 'Occupato', residente: props.stanza.id_residenti[i] }]);
            } else {
                setLetti(current => [...current, { id: i, stato: 'Libero' }]);
            }
        }
        setStatus(1);
    }

    async function GetStanza(token, id) {  //!esempio controllo scheda in scheda
        props.loading(true);
        let piano = props.stanza.percorso.split(' | ')[0];
        let ala = props.stanza.percorso.split(' | ')[1];
        let corridoio = props.stanza.percorso.split(' | ')[2];
        await strutturaNode.getStrutturaAll(token).then((res) => {
            if(!res.result) throw res.error;
            res.data.map((p) => {
                if (piano === p.id)
                    p[piano].map((a) => {
                        if (ala === a.id)
                            a[ala].map((c) => {
                                if (corridoio === c.id)
                                    c[corridoio].map((s) => {
                                        if (id === s.id)
                                            setStanza(s);
                                        setStanza(current => ({ ...current, percorso: props.stanza.percorso }));
                                        //props.loading(false);
                                    })
                            })
                    })
            });
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_stanza', props.stanza)).finally(() => props.loading(false));
    }


    useEffect(() => {
        setMod(0);
        setStatus(0);
        setStanza(props.stanza);
        setLetti([]);
        if (props.stanza.letti[0].stato === undefined) GetStanza(accountData.token, props.stanza.id); //!esempio controllo scheda in scheda
    }, [props]);

    return (
        <>
            <div id="Header" className="headerPopup" style={{ justifyContent: "start" }}>
                <div className={status === 0 ? selSelettore : defSelettore} onClick={() => setStatus(0)}>
                    <p>{langSet.dettagli}</p>
                </div>
                <div className={status === 1 ? selSelettore : defSelettore} onClick={() => createLetti(1)}>
                    <p>{langSet.statoLetti}</p>
                </div>
                <div className={status === 2 ? selSelettore : `disabled ${defSelettore}`} /* onClick={() => setStatus(2)} */>
                    <p>{langSet.sensoriCamera}</p>
                </div>
                <div className={status === 3 ? selSelettore : `disabled ${defSelettore}`} /* onClick={() => setStatus(3)} */>
                    <p>{langSet.sensoriBagno}</p>
                </div>
            </div>
            <div id="PopupBody">
                <table className="scheda" style={{ display: status === 0 ? "" : "none" }}>
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                {langSet.stanzaN}
                                <p style={{ fontSize: "4em", margin: 0 }}>{stanza.id}</p>
                                <div style={{ fontSize: ".75em", display: "flex", alignItems: "center", padding: "0 2em" }}>
                                    {stanza.letti.map((letto, i) => {
                                        return (
                                            <>
                                                {
                                                    letto.stato === 'Occupato' ?
                                                        <div key={i} className='dot blue medium'>&nbsp;</div>
                                                        :
                                                        letto.stato === 'Libero' ?
                                                            <div key={i} className='dot green medium'>&nbsp;</div>
                                                            :
                                                            null
                                                }
                                            </>
                                        )
                                    })}
                                    <img src={bedB} alt='bed' style={{ width: "1em" }} />&nbsp;x2
                                </div>
                            </td>
                            <td width="85%">
                                <label>{langSet.posizione}</label>
                                <p>{stanza.percorso}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ display: status === 1 ? "" : "none" }}>
                    <table id="TitoliLista" >
                        <tbody>
                            <tr>
                                <td width="20%" style={{ border: 0 }}>{langSet.letto} </td>
                                <td width="20%" style={{ border: 0 }}>{langSet.stato} </td>
                                <td width="60%" style={{ border: 0 }}>{langSet.residente} </td>
                            </tr>
                        </tbody>
                    </table>
                    {stanza.letti.map((letto, i) => {
                        return (
                            <table key={i} id="Lista" style={{ border: 0 }}>
                                <tbody>
                                    <tr>
                                        <td width="20%" >{i + 1}</td>
                                        <td width="20%" > {letto.stato === 'Occupato' ? <div className='dot blue medium normPosition' /> : letto.stato === 'Libero' ? <div className='dot green medium normPosition' /> : <></>} &nbsp;&nbsp; {letto.stato === 'Occupato' ? langSet.occupato : langSet.libero}</td>
                                        <td width="60%" >{letto.residente}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    })}
                </div>
            </div>
        </>
    );
}

export default SchedaStanza;