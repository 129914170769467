import React, { useState, useEffect } from "react";
import Draggable from 'react-draggable';
import PopupComp from '../components/Popup.js';
import close from "../imgs/icons/close.svg";
import modifica from '../imgs/icons/modifica.svg';
import confirmW from '../imgs/icons/confirmW.svg';
import userPurple from '../imgs/icons/userPurple.svg';

import auth from "node/auth/auth.js";

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_utente;

const SchedaUtente = (props) => {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);
    const [mod, setMod] = useState(0);
    const [status, setStatus] = useState(0);
    const [nome, setNome] = useState(props.utente.nome);
    const [cognome, setCognome] = useState(props.utente.cognome);
    const [tipo, setTipo] = useState(props.utente.tipo);
    const [email, setEmail] = useState(props.utente.email);
    const [registrazione, setRegistrazione] = useState(props.utente.registrazione ?? 'None');

    const tipoLetterale = tipo === 0 ? "Admin" : tipo === 1 ? langSet.itInterno : tipo === 2 ? langSet.manutEsterno : tipo === 3 ? langSet.coordinatoreInfermiere : tipo === 4 ? langSet.personaleDiCorsia : langSet.personaleDiCorsia;

    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow";
    const defContinua = "selettore selettoreFlex selettoreBlue";

    function resetData() {
        setNome(props.utente.nome);
        setCognome(props.utente.cognome);
        setTipo(props.utente.tipo);
    }

    async function modificaUtente() {
        props.loading(true);
        let data = { nome: nome, cognome: cognome, tipo: parseInt(tipo) };
        await auth.editUser('accountData.token', props.utente.id, data).then((res) => {
            if (!res.result) throw res.error;
            props.getUtenti();
            setMod(0);
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_utente', props.utente)).finally(() => {
            props.loading(false);
        });
    }

    useEffect(() => {
        setNome(props.utente.nome);
        setCognome(props.utente.cognome);
        setTipo(props.utente.tipo);
        setEmail(props.utente.email);
        setRegistrazione(props.utente.registrazione ?? 'None');
    }, [props.utente]);

    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }

    function openPopup(category, info, operation, data) {
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    return (
        <>
            <div id="Popup">
                <div onClick={() => centraPopup()} id="options">
                    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}
                </div>
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} loading={props.loading} closePopup={closePopup} />
                    </div>
                </Draggable>
            </div>
            <div id="Header" className="headerPopup" style={{ justifyContent: "start" }}>
                <div className={selSelettore}>
                    <p>{langSet.dettagli}</p>
                </div>
                {/* <div className={status === 1 ? selSelettore : defSelettore}>
                    <p>Dashboard</p>
                </div> */}
            </div>
            <div id="PopupBody">
                <table className="scheda" style={{ display: status === 0 ? "" : "none" }}>
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                <img src={userPurple} alt="position" />
                            </td>
                            <td width="17.5%">
                                <label>{langSet.nome}</label>
                                {mod === 0 ? <p>{nome}</p> :
                                    <input
                                        type="text"
                                        value={nome}
                                        placeholder={langSet.inserisciNome}
                                        onChange={(e) => setNome(e.target.value)}
                                    />
                                }
                            </td>
                            <td width="17.5%">
                                <div>
                                    <label>{langSet.cognome}</label>
                                    {mod === 0 ? <p>{cognome}</p> :
                                        <input
                                            type="text"
                                            value={cognome}
                                            placeholder={langSet.inserisciCognome}
                                            onChange={(e) => setCognome(e.target.value)}
                                        />
                                    }
                                </div>
                            </td>
                            <td width="17.5%">
                                <div>
                                    <label>Email</label>
                                    <p>{email}</p>
                                </div>
                            </td>
                            <td width="33.5%" valign="bottom" rowSpan={2}>
                                {mod === 0 ?
                                    <div
                                        className="selettore selettoreFlex selettoreViolet"
                                        onClick={() => setMod(1)}
                                    >
                                        <p>{langSet.modifica}</p>
                                        <img src={modifica} alt='modifica' style={{ width: "1em" }} />
                                    </div>
                                    : <>
                                        <div className={defContinua} onClick={() => modificaUtente()} >
                                            <p>{langSet.salva}</p>
                                            <img src={confirmW} alt='arrow' />
                                        </div>
                                        <div className="selettore selettoreFlex selettoreViolet" onClick={() => { resetData(); setMod(0); }}>
                                            <p>{langSet.annulla}</p>
                                            <img src={close} alt="close" />
                                        </div>
                                    </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>{langSet.tipoDiUtenza}</label>
                                {accountData.email === email ?
                                    <p>{tipoLetterale}</p> :
                                    mod === 1 && accountData.tipo === 0 ?
                                        <select value={tipo} onChange={(e) => setTipo(e.target.value)}>
                                            <option value={0}>Admin</option>
                                            <option value={1}>{langSet.itInterno}</option>
                                            <option value={2}>{langSet.manutEsterno}</option>
                                            <option value={3}>{langSet.coordinatoreInfermiere}</option>
                                            <option value={4}>{langSet.personaleDiCorsia}</option>
                                        </select> :
                                        <p>{tipoLetterale}</p>
                                }
                            </td>
                            <td>
                                <label>{langSet.dataDiRegistrazione}</label>
                                <p style={{ width: "10em", position: "relative", top: ".25em" }}>
                                    {registrazione}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="scheda" style={{ display: status === 1 ? "" : "none" }}>
                    <table id="TitoliLista" >
                        <tbody>
                            <tr>
                                <td width="15%" style={{ border: 0 }}>{langSet.nome} </td>
                                <td width="25%" style={{ border: 0 }}>ID </td>
                                <td width="30%" style={{ border: 0 }}>{langSet.tipo} </td>
                                <td width="30%" style={{ border: 0 }}>{langSet.stato} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default SchedaUtente;