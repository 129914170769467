import axios from 'axios';
import * as Dash from './dashboard_model.js';
import * as Crypto from '../crypt/crypt.js';
import * as Auth from '../auth/auth.js';

/*--------------------- OTTENGO LA DASHBOARD ---------------------*/
async function getDashboard(token, sort, orderby) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/dashboard`, { params: { sortby: sort, orderby: orderby }, headers });
    
    let dashboard = JSON.parse(Crypto.decrypt(response.data));
    
    let data = [];

    for (let dati of dashboard.dashboard) {
      dati.id_piano = dashboard.id_piano;
      data.push(new Dash.GetDashCard(dati));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

export default { getDashboard };