import React, { useState, useEffect } from "react";

import logo from "../imgs/tekcare_orizz_white@4x.png";
import dashboard from "../imgs/icons/dashboard.svg";
import residenti from "../imgs/icons/residenti.svg";
import struttura from "../imgs/icons/struttura.svg";
import notifiche from "../imgs/icons/notifiche.svg";
import sensori from "../imgs/icons/sensori.svg";
import utenti from "../imgs/icons/utenti.svg";
import dashboardW from "../imgs/icons/dashboardW.svg";
import residentiW from "../imgs/icons/residentiW.svg";
import strutturaW from "../imgs/icons/strutturaW.svg";
import notificheW from "../imgs/icons/notificheW.svg";
import sensoriW from "../imgs/icons/sensoriW.svg";
import utentiW from "../imgs/icons/utentiW.svg";
import wand from "../imgs/wand.cur";

import Menu from "../components/Menu.js";
import Header from "../components/Header.js";

import Dashboard from "../pages/dashboard/index.js";
import Residenti from "../pages/residenti/index.js";
import Struttura from "../pages/struttura/index.js";
import Notifiche from "../pages/notifiche/index.js";
import Sensori from "../pages/sensori/index.js";
import Utenti from "../pages/utenti/index.js";

function loading(bool) {
  bool ?
    document.getElementById("loading").style.display = "block"
    :
    document.getElementById("loading").style.display = "none";
}

function Home() {
  const [page, setPage] = useState(null);
  const [title, setTitle] = useState("");
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [keysPressed, setKeysPressed] = useState("");

  useEffect(() => {
    function handleKeyDown(event) {
      const { key } = event;
      setKeysPressed((prev) => prev + key);
    }

    function handleAlert() {
      if (keysPressed.includes("GANDALF")) {
        document.getElementById("Base").style.background = "url('https://media.tenor.com/VOdWjm2zbEAAAAAC/gandalf-sax-guy.gif')";
        document.getElementById("Base").style.backgroundSize = "cover";
        document.getElementById("Base").style.cursor = "url(" + wand + "), auto";
        document.getElementById("Content").style.background = "rgba(255,255,255,0.7)";
        setKeysPressed("");
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    handleAlert();
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [keysPressed]);

  function refreshSensori() {
    setPage(<Sensori loading={loading} sortBy='nome' orderBy='asc' />);
  }

  function refreshStruttura() {
    setPage(<Struttura loading={loading} />);
  }

  function refreshUtenti() {
    setPage(<Utenti loading={loading} sortBy='nome' orderBy='asc' />);
  }

  function refreshNotifiche() {
    setPage(<Notifiche loading={loading} sortBy='creato' orderBy='asc' />);
  }

  function refreshResidenti() {
    setPage(<Residenti loading={loading} sortBy='cognome' orderBy='asc'/>);
  }

  function refreshDashboard() {
    setPage(<Dashboard filtro='griglia' Naviga={Naviga} loading={loading} />);
  }

  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login === "true-no-remember") {
      localStorage.setItem("login", false);
    }
    let lang = localStorage.getItem("lang");
    if (lang === null) {
      localStorage.setItem("lang", "it");
    }
  }, []);

  function cerca(value) { //! questa ricerca funziona solo nelle liste, non nelle pagine
    let page = document.getElementById("ContentPage");
    let rows = page.getElementsByTagName("table");
    for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        let td = row.getElementsByTagName("td")[0];
        if (td) {
            let txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(value.toUpperCase()) > -1) {
                row.style.display = "";
            } else {
                row.style.display = "none";
            }
        }
    }
}

  function Naviga(title, filtro) {
    let xpage = null;
    switch (title) {
      case "Dashboard":
        if (filtro === undefined) filtro = "griglia";
        xpage = <Dashboard filtro={filtro} Naviga={Naviga} loading={loading} cerca={cerca}/>;
        break;
      case "Residenti":
        xpage = <Residenti loading={loading} cerca={cerca}/>;
        break;
      case "Struttura":
        xpage = <Struttura loading={loading} cerca={cerca}/>;
        break;
      case "Notifiche":
        xpage = <Notifiche loading={loading} sortBy='creato' orderBy='asc' cerca={cerca}/>;
        break;
      case "Sensori":
        xpage = <Sensori loading={loading} sortBy='nome' orderBy='asc' cerca={cerca}/>;
        break;
      case "Utenti":
        xpage = <Utenti loading={loading} sortBy='nome' orderBy='asc' cerca={cerca}/>;
        break;
      default:
        break;
    }
    setTitle(title);
    setShouldAnimate(true);
    setPage(xpage);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldAnimate(false);
    }, 400);
    return () => clearTimeout(timeoutId);
  }, [page]);

  useEffect(() => {
    if (title === '') Naviga('Dashboard');
  }, [title]);

  return (
    <div id="Base">
      <div id="Menu">
        <div className="menuTop">
          <img src={logo} alt="logo" style={{ width: "100%" }} />
        </div>
        <div className="menuContent">
          <Menu
            Naviga={Naviga}
            sel={title}
            items={[
              ["Dashboard", [dashboard, dashboardW]],
              ["Residenti", [residenti, residentiW]],
              ["Struttura", [struttura, strutturaW]],
              ["Notifiche", [notifiche, notificheW]],
              ["Sensori", [sensori, sensoriW]],
              ["Utenti", [utenti, utentiW]],
              //menu-titolo, icone
            ]}
          />
        </div>
      </div>
      <div id="Content">
        <div id="Header" style={{ background: "#F2F5FF" }}>
          <Header title={title} refreshDashboard={refreshDashboard} refreshSensori={refreshSensori} refreshStruttura={refreshStruttura} refreshUtenti={refreshUtenti} refreshNotifiche={refreshNotifiche} refreshResidenti={refreshResidenti} Naviga={Naviga} />
        </div>
        <div id="Body" className={shouldAnimate ? "fade-in" : ""}>
          <React.Fragment>
            {page}
          </React.Fragment>
        </div>
      </div>
    </div>
  );

}

export default Home;