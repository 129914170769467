import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import PopupComp from '../../components/Popup.js';

import arrowPurple from '../../imgs/icons/arrowPurple.svg';
import deletW from '../../imgs/icons/deleteW.svg';
import modifica from '../../imgs/icons/modifica.svg';
import addW from '../../imgs/icons/addW.svg';
import add from '../../imgs/icons/add.svg';
/* import luminosita from '../../imgs/icons/luminosita.svg';
import allagamento from '../../imgs/icons/allagamento.svg';
import sos from '../../imgs/icons/sos.svg';
import temperatura from '../../imgs/icons/temperatura.svg';
import radio from '../../imgs/icons/radio.svg';
import co2 from '../../imgs/icons/co2.svg'; */
import bedB from '../../imgs/icons/bedB.svg';
import close from '../../imgs/icons/close.svg';
import closeW from '../../imgs/icons/closeW.svg';
import confirmW from '../../imgs/icons/confirmW.svg';
import save from '../../imgs/icons/save.svg';

import strutturaNode from 'node/struttura/struttura.js';
import magazzinoNode from 'node/magazzino/magazzino.js';
import pianiNode from 'node/piani/piani.js';
import aleNode from 'node/ale/ale.js';
import corridoiNode from 'node/corridoi/corridoi.js';


import lang from "../../lang.json";
const langSet = lang[localStorage.getItem("lang")].struttura;
const langAlarms = lang[localStorage.getItem("lang")].alarms;

export default function Index(props) {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);
    const [struttura, setStruttura] = useState([]);
    const [strutturaMod, setStrutturaMod] = useState("Attiva Modifiche");
    const [pianoID, setPianoID] = useState("");
    const [piano, setPiano] = useState("");
    const [alaID, setAlaID] = useState("");
    const [ala, setAla] = useState("");
    const [corridoioID, setCorridoioID] = useState("");
    const [corridoioThinkin, setCorridoioThinkin] = useState("");
    const [corridoio, setCorridoio] = useState("");
    const [stanzaID, setStanzaID] = useState("");
    const [stanza, setStanza] = useState("");
    const [statusMod, setStatusMod] = useState([]);
    const [mag, setMag] = useState();

    function openStanza(id_piano, id_ala, id_corridoio, stanza) {
        stanza.percorso = `${id_piano} | ${id_ala} | ${id_corridoio}`;
        openPopup("scheda_stanza", stanza);
    }

    async function getStrInfo() {
        props.loading(true);
        await strutturaNode.getStrutturaInfo(accountData.token).then((res) => {
            if (!res.result) throw res.error;
            openPopup('scheda_struttura', res.data);
        }).catch((e) => props.SchedaInScheda('alarm', e.toString())).finally(() => {
            props.loading(false);
        });
    }

    async function editDesc(what, id, desc) {
        props.loading(true);

        let response = {};

        switch (what) {
            case 'piano':
                response = await pianiNode.editDescrizione(accountData.token, id, desc);
                break;
            case 'ala':
                response = await aleNode.editDescrizione(accountData.token, id, desc);
                break;
            case 'corridoio':
                response = await corridoiNode.editDescrizione(accountData.token, id, desc);
                break;
            default:
                break;
        }

        if (!response.result) openPopup('alarm', response.error.toString());

        await getStr();

        props.loading(false);
    }

    function magazzino() {
        let magazzino = document.getElementsByClassName('contentMagazzino')[0];
        let close = document.getElementById('closeMagazzino');
        if (magazzino.style.width < 1 || magazzino.style.width === "" || magazzino.style.width === "0px") {
            close.style.display = "block";
            close.style.cursor = "pointer";
            magazzino.style.width = "27.5em";
        } else {
            magazzino.style.width = 0;
            close.style.display = "none";
        }
    }

    function tabStruttura(id) {
        let tab = document.getElementById(id);
        let arrow = document.getElementById('arrow' + id);
        if (tab.style.maxHeight === "2.6em") {
            tab.style.maxHeight = "100em";
            arrow.style.transform = "rotate(180deg)";
        }
        else {
            tab.style.maxHeight = "2.6em";
            arrow.style.transform = "rotate(0deg)";
        }
    }

    async function getStr() {
        props.loading(true);
        let response = await strutturaNode.getStrutturaAll(accountData.token);
        if (!response.result) {
            openPopup('alarm', response.error.toString());
            props.loading(false);
            return;
        }
        setStruttura(response.data);
        props.loading(false);
    }

    async function getMag() {
        props.loading(true);
        let response = await magazzinoNode.getMagazzinoStruttura(accountData.token);
        if (!response.result) {
            openPopup('alarm', response.error.toString());
            props.loading(false);
            return;
        }
        setMag(response.data);
        props.loading(false);
    }

    async function aggiungi(tipo, document_id, id) {
        try {
            props.loading(true);
            let response = {};
            switch (tipo) {
                case 'piano':
                    response = await pianiNode.addPiano(accountData.token, pianoID, piano);
                    setPianoID("");
                    setPiano("");
                    break;
                case 'ala':
                    response = await aleNode.addAla(accountData.token, alaID, ala, id);
                    setAlaID("");
                    setAla("");
                    break;
                case 'corridoio':
                    response = await corridoiNode.addCorridoio(accountData.token, corridoioID, corridoio, corridoioThinkin, id);
                    setCorridoioID("");
                    setCorridoio("");
                    break;
                default:
                    break;
            }

            if (!response.result) throw response.message;

            await getStr();
            switchAdd(document_id);
            props.loading(false);
            resetMod();
            return;
        } catch (e) {
            openPopup("alarm", e.toString());
            props.loading(false);
            console.log(e);
            return;
        }
    }

    useEffect(() => {
        props.loading(true);
        setTimeout(() => {
            getStr();
            getMag();
            props.loading(false);
        }, 500)
    }, [localStorage.getItem("struttura")]);

    function mod() {
        strutturaMod === "Attiva Modifiche" ? setStrutturaMod("Disattiva Modifiche") : setStrutturaMod("Attiva Modifiche");
        let menuElements = document.getElementsByClassName('hov');
        let addElements = document.getElementsByClassName('add');
        for (let i = 0; i < addElements.length; i++) {
            strutturaMod === "Attiva Modifiche" ? addElements[i].style.display = "inherit" : addElements[i].style.display = "none";
        }
        for (let i = 0; i < menuElements.length; i++) {
            strutturaMod === "Attiva Modifiche" ? menuElements[i].style.display = "flex" : menuElements[i].style.display = "none";
        }
        for (let i = 0; i < statusMod.length; i++) {
            switchAdd(statusMod[i], 0);
        }
        setStatusMod([]);
    }

    function resetMod() {
        setTimeout(() => {
            let menuElements = document.getElementsByClassName('hov');
            let addElements = document.getElementsByClassName('add');
            for (let i = 0; i < addElements.length; i++) {
                addElements[i].style.display = "inherit";
            }
            for (let i = 0; i < menuElements.length; i++) {
                menuElements[i].style.display = "flex";
            }
        }, 500)
    }

    function switchAdd(t, x) {
        setStatusMod([...statusMod, t]);
        let tab = document.getElementById(t);
        let tab2 = document.getElementById(t + "2");
        console.log(tab, tab2);
        if (tab.style.display === "none") {
            tab.style.display = "flex";
            tab2.style.display = "none";
        }
        else {
            tab.style.display = "none";
            if (x !== 0) tab2.style.display = "flex";
        }
    }

    function menu(id, tipo, info, operation, data) {
        return (
            <div className={data === 'full' ? 'modifica hov full' : 'modifica hov'} style={{ display: "none" }}>
                <input type="text" placeholder={data} className='inputMenuMod' defaultValue={data} onChange={(e) => data = e.target.value} />
                <div className='selettoreRound nowidth' style={{ borderColor: "#2B318A" }} onClick={() => editDesc(tipo, id, data)}>
                    <img src={save} alt='save' />
                </div>
                <div className='selettoreRound nowidth' style={{ backgroundColor: "#2B318A" }} onClick={() => openPopup("alarm", info, operation, id)}>
                    <img src={deletW} alt='deleteW' />
                </div>&nbsp;
            </div>
        );
    }

    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }

    function openPopup(category, info, operation, data) {
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    function SchedaInScheda(category, info, operation, data, previousPage) {
        setInfosPopup([{ category, info, operation, data, previousPage }]);
    }

    return (
        <>
            <div id="loading" />
            <div id="Popup">
                <div onClick={() => centraPopup()} id="options">
                    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}
                </div>
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} getStr={getStr} getStrInfo={getStrInfo} loading={props.loading} closePopup={closePopup} SchedaInScheda={SchedaInScheda} />
                    </div>
                </Draggable>
            </div>
            <div id="Header" >
                <div className='headerSX'/>
                   {/*  <input type="search" name="search" autoComplete="off" onChange={(e) => props.cerca(e.target.value)} placeholder={langSet.cerca} />
                </div> */}
                {accountData.tipo === 0 ?
                    <>
                        <div className='headerDX'>
                            <div className='selettore selettoreFlex selettorePurple' style={{ width: "12.5em" }} onClick={() => openPopup('nuova_struttura')}>
                                <p>{langSet.aggiungiStruttura}</p>
                                <img src={addW} alt='add' />
                            </div>
                        </div>
                    </>
                    :
                    <>
                    </>
                }
            </div>
            <div id="ContentPage">
                <table id="TitoliLista">
                    <tbody>
                        <tr>
                            <td className='selected' width="125px">
                                {langSet.nomeStruttura} &nbsp;
                            </td>
                            <td >
                                <div className='mini-selettore selettore selettoreFlex selettoreBorder' style={{ float: "left" }} onClick={() => getStrInfo()}>
                                    <p> {langSet.dettagli} </p>
                                    <img src={arrowPurple} alt='arrow' style={{ transform: "rotate(-90deg)", width: ".9em" }} />
                                </div>
                            </td>
                            <td>
                                <div className={strutturaMod === 'Attiva Modifiche' ? 'mini-selettore selettore selettoreFlex selettoreBorder' : 'mini-selettore selettore selettoreFlex selettoreViolet'} style={{ maxWidth: "10em" }} onClick={() => mod()}>
                                    <p>{strutturaMod === 'Attiva Modifiche' ? langSet.attivaModifiche : langSet.disattivaModifiche}</p>
                                    <img src={modifica} alt='modifica' style={{ width: ".7em" }} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div id="ContentStruttura">
                    <div id="Strutture">
                        {struttura === undefined ?
                            <div id="noData">
                                <p>{langSet.erroreCaricamento}</p>
                            </div>
                            : struttura.map((piano, i) => {
                                return (
                                    <div className='tabStruttura piano' key={i} id={piano.id} style={{ maxHeight: "2.6em" }} >
                                        <div className='headerStruttura' style={{ background: "#CDDEFF" }}>
                                            <div className='mini-tab mini-piano'>
                                                {menu(piano.id, "piano", langAlarms.ilPiano + piano.id + langAlarms.verraEliminato, "deletePiano", piano.descrizione)}
                                                <div>
                                                    <img src={arrowPurple} alt='arrow' id={"arrow" + piano.id} onClick={() => tabStruttura(piano.id)} />
                                                    &emsp;{piano.descrizione}
                                                </div>
                                                {/* <div>
                                                    <div className='selettoreRound mini-right'>
                                                        <img src={sos} alt='sos' id="sos" />
                                                        <div className='topLeft dot green'>&nbsp;</div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            {/* <div className='mini-tab'>
                                                {menu}
                                                Bagno&emsp;
                                                <div>
                                                    <div className='selettoreRound mini-right'>
                                                        <img src={allagamento} alt='allagamento' id="allagamento" />
                                                        <div className='topLeft dot green'>&nbsp;</div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className='add'>
                                                Locale <img src={add} alt='add' />
                                            </div> */}
                                        </div>
                                        {piano[piano.id].map((ala, i) => {
                                            return (
                                                <div className='tabStruttura ala' key={i} id={ala.id} style={{ maxHeight: "2.6em" }}>
                                                    <div className='headerStruttura' style={{ background: "#F2F5FF" }}>
                                                        <div className='mini-tab mini-ala'>
                                                            {menu(ala.id, "ala", langAlarms.lAla + ala.id + langAlarms.verraEliminata, "deleteAla", ala.descrizione)}
                                                            <div>
                                                                <img src={arrowPurple} alt='arrow' id={"arrow" + ala.id} onClick={() => tabStruttura(ala.id)} />
                                                                &emsp;{ala.descrizione}
                                                            </div>
                                                            {/* <div>
                                                                <div className='selettoreRound mini-right'>
                                                                    <img src={radio} alt='radio' id="radio" />
                                                                    <div className='topLeft dot yellow'>&nbsp;</div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    {ala[ala.id].map((corridoio, i) => {
                                                        return (
                                                            <div className='tabStruttura corridoio' key={i} id={corridoio.id} style={{ maxHeight: "2.6em" }}>
                                                                <div className='headerStruttura' style={{ background: "#F8F9FD" }}>
                                                                    <div className='mini-tab mini-corridoio'>
                                                                        {menu(corridoio.id, "corridoio", langAlarms.ilCorridoio + corridoio.id + langAlarms.verraEliminato, "deleteCorridoio", corridoio.descrizione)}
                                                                        <div>
                                                                            <img src={arrowPurple} alt='arrow' id={"arrow" + corridoio.id} onClick={() => tabStruttura(corridoio.id)} />
                                                                            &emsp;{corridoio.descrizione}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {corridoio[corridoio.id].map((stanza, i) => {
                                                                    return (
                                                                        <div className='tabStruttura stanza' key={i} id="Lista">
                                                                            <div className='mini-tab' style={{ fontWeight: 500, minWidth: "13.25em", color: "#000" }}>
                                                                                {stanza.descrizione}
                                                                                <div style={{ fontSize: ".75em", display: "flex", alignItems: "center" }}>
                                                                                    {stanza.letti.map((letto, i) => {
                                                                                        return (
                                                                                            letto.stato === 'Occupato' ?
                                                                                                <div key={i} className='dot blue medium'>&nbsp;</div>
                                                                                                :
                                                                                                letto.stato === 'Libero' ?
                                                                                                    <div key={i} className='dot green medium'>&nbsp;</div>
                                                                                                    :
                                                                                                    null
                                                                                        )
                                                                                    })}
                                                                                    <img src={bedB} alt='bed' style={{ width: "1em" }} />&nbsp;x{stanza.letti.length}
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className='mini-tab mini-tab-stanza'>
                                                                                <div className='selettoreRound mini-right'>
                                                                                    <img src={luminosita} alt='luminosita' id="luminosita" />
                                                                                    <div className='topLeft dot yellow'>&nbsp;</div>
                                                                                </div>
                                                                                <div className='selettoreRound mini-right'>
                                                                                    <img src={co2} alt='co2' id="co2" />
                                                                                    <div className='topLeft dot green'>&nbsp;</div>
                                                                                </div>
                                                                                <div className='selettoreRound mini-right'>
                                                                                    <img src={temperatura} alt='temperatura' id="temperatura" />
                                                                                    <div className='topLeft dot yellow'>&nbsp;</div>
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <div className='mini-tab mini-tab-stanza'>
                                                                                Bagno&emsp;
                                                                                <div>
                                                                                    <div className='selettoreRound mini-right'>
                                                                                        <img src={allagamento} alt='allagamento' id="allagamento" />
                                                                                        <div className='topLeft dot green'>&nbsp;</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                            <div className='selettore selettoreBorder selettoreFlex' style={{ position: "absolute", right: 0, width: "7.5em" }} onClick={() => openStanza(piano.id, ala.id, corridoio.id, stanza)}>
                                                                                <p>{langSet.schedaStanza}</p>
                                                                                <img src={arrowPurple} alt='arrow' style={{ transform: "rotate(-90deg)" }} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                                <div className='tabStruttura stanza add' id="Lista">
                                                                    <div className='headerStruttura'>
                                                                        <div className='mini-tab add' id={corridoio.id + "stanza"} onClick={() => openPopup('nuova_stanza', { piano: piano.id, ala: ala.id, corridoio: corridoio.id })}>
                                                                            {langSet.camera} <img src={add} alt='add' />
                                                                        </div>
                                                                        {/* <div className='add2' id={corridoio.id + "stanza2"}>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Inserisci ID"
                                                                                value={stanzaID}
                                                                                onChange={(e) => setStanzaID(e.target.value)}
                                                                                style={{ margin: 0 }}
                                                                            />
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Inserisci Descrizione"
                                                                                value={stanza}
                                                                                onChange={(e) => setStanza(e.target.value)}
                                                                                style={{ margin: 0 }}
                                                                            />
                                                                            <div className='selettoreRound ' onClick={() => switchAdd(corridoio.id + "stanza")} style={{ borderColor: "#2B318A" }}>
                                                                                <img src={close} alt='close' style={{ margin: 0 }} />
                                                                            </div>
                                                                            <div className='selettoreRound ' style={{ backgroundColor: "#2B318A" }}>
                                                                                <img src={confirmW} alt='confirm' style={{ margin: 0 }} onClick={() => aggiungi("stanza", `${corridoio.id}stanza`, corridoio.id)} />
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className='tabStruttura corridoio add' style={{ maxHeight: "2.6em" }}>
                                                        <div className='headerStruttura' style={{ background: "#F8F9FD" }}>
                                                            <div className='mini-tab add' id={ala.id + "corridoio"} onClick={() => switchAdd(ala.id + "corridoio")}>
                                                                {langSet.corridoio} <img src={add} alt='add' />
                                                            </div>
                                                            <div className='add2' id={ala.id + "corridoio2"}>
                                                                <input
                                                                    type="text"
                                                                    placeholder={langSet.inserisciId}
                                                                    value={corridoioID}
                                                                    onChange={(e) => setCorridoioID(e.target.value)}
                                                                    style={{ margin: "0 .5em 0 0", width: "5em" }}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    placeholder="ID Thinkin"
                                                                    value={corridoioThinkin}
                                                                    onChange={(e) => setCorridoioThinkin(e.target.value)}
                                                                    style={{ margin: "0 .5em 0 0", width: "5em" }}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    placeholder={langSet.inserisciDescrizione}
                                                                    value={corridoio}
                                                                    onChange={(e) => setCorridoio(e.target.value)}
                                                                    style={{ margin: 0 }}
                                                                />
                                                                <div className='selettoreRound ' onClick={() => switchAdd(ala.id + "corridoio")} style={{ borderColor: "#2B318A" }}>
                                                                    <img src={close} alt='close' style={{ margin: 0 }} />
                                                                </div>
                                                                <div className='selettoreRound ' style={{ backgroundColor: "#2B318A" }} onClick={() => aggiungi("corridoio", `${ala.id}corridoio`, ala.id)}>
                                                                    <img src={confirmW} alt='confirm' style={{ margin: 0 }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className='tabStruttura ala add' style={{ maxHeight: "2.6em" }}>
                                            <div className='headerStruttura' style={{ background: "#F2F5FF" }}>
                                                <div className='mini-tab add' id={piano.id + "ala"} onClick={() => switchAdd(piano.id + "ala")}>
                                                    {langSet.ala} <img src={add} alt='add' />
                                                </div>
                                                <div className='add2' id={piano.id + "ala2"}>
                                                    <input
                                                        type="text"
                                                        value={alaID}
                                                        placeholder={langSet.inserisciId}
                                                        onChange={(e) => setAlaID(e.target.value)}
                                                        style={{ margin: "0 .5em 0 0", width: "5em" }}
                                                    />
                                                    <input
                                                        type="text"
                                                        value={ala}
                                                        placeholder={langSet.inserisciDescrizione}
                                                        onChange={(e) => setAla(e.target.value)}
                                                        style={{ margin: 0 }}
                                                    />
                                                    <div className='selettoreRound' onClick={() => switchAdd(piano.id + "ala")} style={{ borderColor: "#2B318A" }}>
                                                        <img src={close} alt='close' style={{ margin: 0 }} />
                                                    </div>
                                                    <div className='selettoreRound' style={{ backgroundColor: "#2B318A" }} onClick={() => aggiungi("ala", `${piano.id}ala`, piano.id)}>
                                                        <img src={confirmW} alt='confirm' style={{ margin: 0 }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        <div className='tabStruttura piano add' style={{ maxHeight: "2.6em", padding: 0 }}>
                            <div className='headerStruttura' style={{ background: "#CDDEFF" }}>
                                <div className='mini-tab add' id="piano" onClick={() => switchAdd("piano")}>
                                    {langSet.piano} <img src={add} alt='add' />
                                </div>
                                <div className='add2' id="piano2">
                                    <input
                                        type="text"
                                        placeholder={langSet.inserisciId}
                                        value={pianoID}
                                        onChange={(e) => setPianoID(e.target.value)}
                                        style={{ margin: "0 .5em 0 0", width: "5em" }}
                                    />
                                    <input
                                        type="text"
                                        placeholder={langSet.inserisciDescrizione}
                                        value={piano}
                                        onChange={(e) => setPiano(e.target.value)}
                                        style={{ margin: 0 }}
                                    />
                                    <div className='selettoreRound' onClick={() => switchAdd("piano")} style={{ borderColor: "#2B318A" }}>
                                        <img src={close} alt='close' style={{ margin: 0 }} />
                                    </div>
                                    <div className='selettoreRound' style={{ backgroundColor: "#2B318A" }} onClick={() => aggiungi("piano", "piano")}>
                                        <img src={confirmW} alt='confirm' style={{ margin: 0 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="Magazzino">
                        <div className='headerMagazzino'>
                            <img src={closeW} alt='close' id="closeMagazzino" onClick={() => magazzino()} />
                            <p id="textMagazzino" onClick={() => magazzino()}>{langSet.magazzino}</p>
                        </div>
                        <div className='contentMagazzino'>
                            {mag ? mag.length > 0 ? mag.map((sensore, i) => {
                                return (
                                    <div className='tabMagazzino' style={{ backgroundColor: sensore.quarantena ? "rgb(251, 230, 229)" : null }} key={i}>
                                        <div>
                                            <p style={{ minWidth: "7.5em", color: "rgba(0,0,0,.25)" }}>{sensore.tipo}</p>
                                            <p>[{sensore.id}]&ensp;{sensore.nome}</p>
                                        </div>
                                        <div className={sensore.quarantena ? "dot red normPosition" : "dot green normPosition"} />
                                    </div>
                                )
                            }) : <div id="noData"><p>{langSet.magazzinoVuoto}</p></div> : <div id="noData"><p>{langSet.erroreMagazzino}</p></div>
                            }

                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}