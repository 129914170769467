import React, { useState } from "react";

import close from "../imgs/icons/close.svg";
import confirmW from "../imgs/icons/confirmW.svg";
import arrow from "../imgs/icons/arrowFull.svg";

import NuovoResidente from "./NuovoResidente.js";
import NuovoSensore from "./NuovoSensore.js";
import SchedaUtente from "./SchedaUtente.js";
import ProgressBar from "./ProgressBar.js";
import SchedaStanza from './SchedaStanza.js';
import SchedaSensore from "./SchedaSensore.js";
import SchedaNotifica from "./SchedaNotifica.js";
import SchedaResidente from "./SchedaResidente.js";
import DashboardResidente from "./DashboardResidente.js";
import NuovoUtente from "./NuovoUtente.js";
import NuovaStanza from "./NuovaStanza.js";
import SchedaStruttura from "./SchedaStruttura.js";
import NuovaStruttura from "./NuovaStruttura.js";

import residentiNode from "node/residenti/residenti.js";
import sensoriNode from "node/sensori/sensori.js";
import corridoiNode from 'node/corridoi/corridoi.js';
import pianiNode from 'node/piani/piani.js';
import alaNode from 'node/ale/ale.js';
import authNode from "node/auth/auth.js";
import magazzinoGeneraleNode from 'node/magazzino-generale/magazzino_generale.js';

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")];

const Popup = (props) => {
    let handleContent = null;
    let bodyContent = null;
    let footerContent = null;
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [status, setStatus] = useState(0);

    const defSelettore = "selettore selettoreFlex selettoreBorder";
    const selSelettore = "selettore selettoreFlex selettorePurple";

    switch (props.info[0].category) {
        case 'residente':
            handleContent = (
                <>
                    <div className={status === 0 ? selSelettore : defSelettore} style={{ marginLeft: "1em" }} id="residente" onClick={() => setStatus(0)}>
                        <p>{langSet.dashboard_residente.residente}</p>
                        <div className={props.info[0].data.allarme ? "dot red medium" : "dot green medium"} style={{ right: 0 }} />
                    </div>
                    <div className={status === 1 ? selSelettore : defSelettore} id="stanza" onClick={() => setStatus(1)} >
                        <p>{langSet.dashboard_residente.stanza}</p>
                        <div className={props.info[0].data.allarme ? "dot red medium" : "dot green medium"} style={{ right: 0 }} />
                    </div>
                    <div className={status === 2 ? selSelettore : defSelettore + " disabled"} id="bagno">
                        <p>{langSet.dashboard_residente.bagno}</p>
                        <div className="dot medium" style={{ right: 0 }} />
                    </div>
                </>
            );
            bodyContent = (
                <DashboardResidente info={props.info[0].data} status={status} SchedaInScheda={props.SchedaInScheda} loading={props.loading} closePopup={props.closePopup}/>
            );
            break;
        case 'ambiente':
            handleContent = (
                <>
                    <div className="selettore selettoreFlex selettorePurple" style={{ marginLeft: "1em" }} id="ambiente">
                        <p>{langSet.dashboard.ambiente}</p>
                        <div className="dot green medium" style={{ right: 0 }} />
                    </div>
                    <div className="selettore selettoreFlex selettoreBorder" id="bagno">
                        <p>Bagno</p>
                        <div className="dot red medium" style={{ right: 0 }} />
                    </div>
                </>
            );
            bodyContent = (
                <>
                    <div>Contenuto specifico per la categoria ambiente</div>
                    <br /><br />
                    {props.info[0].category}
                </>
            );
            break;
        case 'scheda_residente':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_dashboard" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("residente", '', '', props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_notifica" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_notifica", props.info[0].data)} /> : null}
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_residente.schedaResidente}</div>
                </>
            );
            bodyContent = (
                <SchedaResidente closePopup={props.closePopup} loading={props.loading} getRes={props.getRes} residente={props.info[0].info} SchedaInScheda={props.SchedaInScheda}/>
            );
            break;
        case 'nuovo_residente':
            handleContent = (

                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_residente.nuovoResidente}</div>
            );
            bodyContent = (
                <NuovoResidente closePopup={props.closePopup} loading={props.loading} getRes={props.getRes} SchedaInScheda={props.SchedaInScheda}/>
            );
            break;
        case 'nuovo_sensore':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_sensore.nuovoSensore}</div>
            );
            bodyContent = (
                <>
                    <NuovoSensore closePopup={props.closePopup} loading={props.loading} getSens={props.getSens} SchedaInScheda={props.SchedaInScheda}/>
                </>
            );
            break;
        case 'nuovo_utente':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_utente.nuovoUtente}</div>
            );
            bodyContent = (
                <>
                    <NuovoUtente closePopup={props.closePopup} loading={props.loading} getUtenti={props.getUtenti} SchedaInScheda={props.SchedaInScheda}/>
                </>
            );
            break;
        case 'nuova_stanza':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_stanza.nuovaStanza}</div>
            );
            bodyContent = (
                <>
                    <NuovaStanza closePopup={props.closePopup} loading={props.loading} getStr={props.getStr} percorso={props.info[0].info} SchedaInScheda={props.SchedaInScheda}/>
                </>
            );
            break;

        case 'scheda_utente':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_utente.schedaUtente}</div>
            );
            bodyContent = (
                <>
                    <SchedaUtente closePopup={props.closePopup} loading={props.loading} utente={props.info[0].info} getUtenti={props.getUtenti} SchedaInScheda={props.SchedaInScheda}/>
                </>
            );
            break;
        case 'scheda_sensore':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_dashboard" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("residente", '', '', props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_notifica" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_notifica", props.info[0].data)} /> : null}
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_sensore.schedaSensore}</div>
                </>
            );
            bodyContent = (
                <>
                    <SchedaSensore closePopup={props.closePopup} loading={props.loading} sensore={props.info[0].info} getSens={props.getSens} SchedaInScheda={props.SchedaInScheda}/>
                </>
            );
            break;
        case 'scheda_notifica':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_notifica.schedaNotifica}</div>
            );
            bodyContent = (
                <>
                    <SchedaNotifica closePopup={props.closePopup} loading={props.loading} notifica={props.info[0].info} getNot={props.getNot} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'scheda_stanza':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_dashboard" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("residente", '', '', props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_notifica" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_notifica", props.info[0].data)} /> : null}
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_stanza.schedaStanza}</div>
                </>

            );
            bodyContent = (
                <>
                    <SchedaStanza closePopup={props.closePopup} loading={props.loading} stanza={props.info[0].info} getStr={props.getStr} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'nuova_struttura':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_struttura.nuovaStruttra}</div>
            );
            bodyContent = (
                <>
                    <NuovaStruttura closePopup={props.closePopup} loading={props.loading} getStr={props.getStr} SchedaInScheda={props.SchedaInScheda}/>
                </>
            );
            break;
        case 'scheda_struttura':
            handleContent = (
                <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.scheda_struttura.schedaStruttura}</div>
            );
            bodyContent = (
                <>
                    <SchedaStruttura closePopup={props.closePopup} loading={props.loading} refresh={props.getStr} getStrInfo={props.getStrInfo} struttura={props.info[0].info} SchedaInScheda={props.SchedaInScheda} />
                </>
            );
            break;
        case 'alarm':
            handleContent = (
                <>
                    {props.info[0].operation === "ritorna_notifica" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_notifica", props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_utente" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_utente", props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_struttura" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_struttura", props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_stanza" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_stanza", props.info[0].data)} /> : null}
                    {props.info[0].operation === "ritorna_sensore" ? <img src={arrow} alt="arrow" className="popupNavigatorArrow" onClick={() => props.SchedaInScheda("scheda_sensore", props.info[0].data)} /> : null}
                    <div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.alarms.avviso}</div>
                </>
            );
            bodyContent = (
                <>
                    <div id="PopupBody">
                        {props.info[0].info}
                    </div>
                </>
            );
            if (props.info[0].operation === "logout") {
                footerContent = (
                    <>
                        <div className="selettore selettoreFlex selettoreBlue" onClick={() => props.Logout()} >
                            <p>{langSet.alarms.esciOra}</p>
                            <img src={confirmW} alt="confirm" />
                        </div>
                        <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                            <p>{langSet.alarms.annulla}</p>
                            <img src={close} alt="close" />
                        </div>
                        <br />
                        <ProgressBar Logout={props.Logout} />
                    </>
                );
            }
            if (props.info[0].operation === "deleteResidente") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img src={close} alt="close" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue" onClick={() => residentiNode.archiviaResidente(accountData.token, props.info[0].data.id).then(() => props.getRes().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img src={confirmW} alt="confirm" />
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteUtente") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img src={close} alt="close" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue" onClick={() => authNode.deleteUser(accountData.token, props.info[0].data.id).then(() => props.getUtenti().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img src={confirmW} alt="confirm" />
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteSensore") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img src={close} alt="close" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue" onClick={() => sensoriNode.patchSensoreMagazzino(accountData.token, props.info[0].data.id, false).then(() => props.getSens().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img src={confirmW} alt="confirm" />
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteSensoreMagazzinoGen") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img src={close} alt="close" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue" onClick={() => magazzinoGeneraleNode.deleteSensoreMagazzinoGenerale(accountData.token, props.info[0].data.id).then(() => props.getSens().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img src={confirmW} alt="confirm" />
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deletePiano") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img src={close} alt="close" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue" onClick={() => pianiNode.deletePiano(accountData.token, props.info[0].data).then(() => props.getStr().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img src={confirmW} alt="confirm" />
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteAla") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img src={close} alt="close" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue" onClick={() => alaNode.deleteAla(accountData.token, props.info[0].data).then(() => props.getStr().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img src={confirmW} alt="confirm" />
                            </div>
                        </div>
                    </>
                );
            }
            if (props.info[0].operation === "deleteCorridoio") {
                footerContent = (
                    <>
                        <div id="PopupFooter">
                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                <p>{langSet.alarms.annulla}</p>
                                <img src={close} alt="close" />
                            </div>
                            <div className="selettore selettoreFlex selettoreBlue" onClick={() => corridoiNode.deleteCorridoio(accountData.token, props.info[0].data).then(() => props.getStr().then(() => props.closePopup()))}>
                                <p>{langSet.alarms.conferma}</p>
                                <img src={confirmW} alt="confirm" />
                            </div>
                        </div>
                    </>
                );
            }
            break;
        default:
            handleContent = (<div style={{ fontSize: "200%", marginLeft: ".5em" }}>{langSet.alarms.errore}</div>);
            bodyContent = (<div>{langSet.alarms.errore}</div>);
            break;
    }

    return (
        <>
            <div id="handle">
                {props.info[0].operation !== "logout" ? <img src={close} alt="close" className="closePopup" onClick={() => props.closePopup()} /> : null}
                {handleContent}
            </div>
            <div id="PopupBody" style={{ width: props.info[0].category !== "alarm" ? "85em" : "30em" }}>
                {bodyContent}
                {footerContent}
            </div>
        </>
    );
};

export default Popup;