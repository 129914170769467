import React, { useState, useEffect, useRef } from "react";

import arrow from "../imgs/icons/arrowW.svg";
import arrowFull from "../imgs/icons/arrowFull.svg";
import close from "../imgs/icons/close.svg";
import userPurple from "../imgs/icons/userPurple.svg";
import residentiPurple from "../imgs/icons/residentiPurple.svg";
import delet from '../imgs/icons/delete.svg';
import confirmW from '../imgs/icons/confirmW.svg';

import magazzinoNode from 'node/magazzino/magazzino.js';
import stanzeNode from "node/stanze/stanze.js";
import residentiNode from "node/residenti/residenti.js";
import strutturaNode from "node/struttura/struttura.js";

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_residente;

const NuovoResidente = (props) => {
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [id, setId] = useState("");
    const [status, setStatus] = useState(0);
    const [stanze, setStanze] = useState([]);
    const [camera, setCamera] = useState(0);
    const [sensori, setSensori] = useState([]);
    const [sensoriSel, setSensoriSel] = useState([]);
    const [tipoSensori, setTipoSensori] = useState([]);
    const sort = "id";
    const order = "asc";
    const accountData = JSON.parse(localStorage.getItem("account"));

    useEffect(() => {
        setStatus(0);
        setId("");
        setNome("");
        setCognome("");
        setCamera(0);
        setSensoriSel([]);
    }, [props]);

    /*     function scroll(dir) {
            const item = scrollRef.current;
            const distance = 150;
            const duration = 350;
            const increment = distance / duration * 10;
            let counter = 0;
            let intervalId;
            if (dir === "left") {
                intervalId = setInterval(() => {
                    item.scrollBy(-increment, 0);
                    counter += 10;
                    if (counter >= duration) clearInterval(intervalId);
                }, 10);
            } else {
                intervalId = setInterval(() => {
                    item.scrollBy(increment, 0);
                    counter += 10;
                    if (counter >= duration) clearInterval(intervalId);
                }, 10);
            }
        } */


    /* useEffect(() => {
        const item = scrollRef.current;
        item.addEventListener("wheel", function (e) {
            if (e.deltaY > 0) item.scrollLeft += 20;
            else item.scrollLeft -= 20;
        });
    }, []); */

    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder noCursor";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow noCursor";
    const defContinua = "selettore selettoreFlex selettoreBlue";

    async function getLibere() {
        await stanzeNode.getStanzeLibere(accountData.token).then((res) => {
            setStanze(res.data);
        });
    }

    async function getSensori() {
        await magazzinoNode.getMagazzinoStruttura(accountData.token, sort, order).then((res) => {
            setSensori(res.data);
        });
    }

    /* async function getStruttura() {
        await strutturaNode.getStrutturaAll(accountData.token).then((res) => {
            setStruttura(res.data);
        });
    } */

    function Sens(azione, id) {
        if (azione === 'add') {
            setSensoriSel([...sensoriSel, { id: id, alarms: [] }]);
            setTipoSensori([...tipoSensori, sensori.filter((item) => item.id === id)[0].tipo]);
        } else {
            setSensoriSel(sensoriSel.filter((item) => item !== id));
            setTipoSensori(tipoSensori.filter((item) => item !== sensori.filter((item) => item.id === id)[0].tipo));
        }
    }

    async function addResidente() {
        props.loading(true);
        setStatus(3);
        await residentiNode.addResidente(accountData.token, id, nome, cognome, camera, sensoriSel).then(() => {
            props.getRes().finally(() => {
                setStatus(0);
                props.closePopup();
                props.loading(false);
            });
        });
    }

    useEffect(() => {
        getLibere();
        getSensori();
        //getStruttura();
    }, []);

    return (
        <>
            <div id="Header" className="headerPopup" style={{ justifyContent: "start" }}>
                <div className={status === 0 ? selSelettore : defSelettore}>
                    <p>{langSet.datiPersonali}</p>
                </div>
                <div className={status > 1 ? defSelettore : status === 1 ? selSelettore : "disabled " + defSelettore}>
                    <p>{langSet.stanza}</p>
                </div>
                <div className={status > 2 ? defSelettore : status === 2 ? selSelettore : "disabled " + defSelettore}>
                    <p>{langSet.sensoriPersonali}</p>
                </div>
            </div>
            <div id="PopupBody">
                <table className="scheda" style={{ display: status === 0 ? "" : "none" }}>
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                <img src={userPurple} alt="position" />
                            </td>
                            <td width="17.5%">
                                <label>{langSet.nome}</label>
                                <input
                                    type="text"
                                    placeholder={langSet.inserisciNome}
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                />
                            </td>
                            <td width="17.5%">
                                <div>
                                    <label>{langSet.cognome}</label>
                                    <input
                                        type="text"
                                        value={cognome}
                                        placeholder={langSet.inserisciCognome}
                                        onChange={(e) => setCognome(e.target.value)}
                                    />
                                </div>
                            </td>
                            <td width="50%" valign="bottom" rowSpan={2}>
                                <div
                                    className={nome !== "" && cognome !== "" && id !== "" ? defContinua : "disabled " + defContinua}
                                    onClick={nome !== "" && cognome !== "" && id !== "" ? () => setStatus(1) : () => { }}
                                >
                                    <p>{langSet.continua}</p>
                                    <img src={arrow} alt='arrow' style={{ transform: "rotate(-90deg)" }} />
                                </div>
                                <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                    <p>{langSet.annulla}</p>
                                    <img src={close} alt="close" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>{langSet.identificativo}</label>
                                <input
                                    type="text"
                                    placeholder={langSet.inserisciIdentificativo}
                                    value={id}
                                    onChange={(e) => setId(e.target.value)}
                                />
                            </td>
                            <td>
                                <label>{langSet.dataDiRegistrazione}</label>
                                <p style={{ width: "10em", position: "relative", top: ".25em" }}>
                                    {new Date().getDate() +
                                        "/" +
                                        (new Date().getMonth() + 1) +
                                        "/" +
                                        new Date().getFullYear()}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="scheda" style={{ display: status === 1 ? "" : "none" }}>
                    <tbody>
                        <tr>
                            <td width="15%" rowSpan={2} className="schedaImg">
                                <img src={residentiPurple} alt="position" />
                            </td>
                            <td width="17.5%">
                                <label>{langSet.camera}</label>
                                <select onChange={(e) => setCamera(e.target.value)} defaultValue={0}>
                                    <option value={0} disabled>{langSet.selezionaCamera}</option>
                                    {stanze === undefined ?
                                        <option disabled>{langSet.erroreCaricamento}</option>
                                        : stanze.map((stanza, index) => {
                                            return (
                                                <option key={index} value={stanza}>
                                                    {stanza}
                                                </option>
                                            );
                                        })}
                                </select>
                            </td>
                            <td valign="bottom" >
                                <div
                                    className={camera !== 0 ? defContinua : "disabled " + defContinua}
                                    onClick={camera !== 0 ? () => setStatus(2) : () => { }}
                                >
                                    <p>{langSet.continua}</p>
                                    <img src={arrow} alt='arrow' style={{ transform: "rotate(-90deg)" }} />
                                </div>
                                <div
                                    className="selettore selettoreFlex selettoreViolet"
                                    onClick={() => setStatus(0)}
                                >
                                    <p>{langSet.indietro}</p>
                                    <img src={arrowFull} alt='arrow' />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ display: status === 2 ? "" : "none" }}>
                    <table id="TitoliLista" >
                        <tbody>
                            <tr>
                                <td width="12.5%" style={{ border: 0 }}>{langSet.nome} </td>
                                <td width="12.5%" style={{ border: 0 }}>ID </td>
                                <td width="12.5%" style={{ border: 0 }}>{langSet.tipo} </td>
                                <td width="7.5%" style={{ border: 0 }}>{langSet.stato} </td>
                                <td width="7.5%" style={{ border: 0 }}>{langSet.batteria} </td>
                                <td width="47.5%" style={{ border: 0 }}></td>
                            </tr>
                        </tbody>
                    </table>
                    <select defaultValue={0} onChange={(e) => Sens("add", e.target.value)} style={{ margin: ".5em 1em", maxWidth: "25em", marginLeft: "1em" }}>
                        <option value={0} disabled>{langSet.selezionaSensore}</option>
                        {sensori === undefined ?
                            <option disabled>{langSet.erroreCaricamento}</option>
                            : sensori.map((sensore, index) => {
                                return (
                                    <option key={index} value={sensore.id}
                                        style={{ display: sensoriSel.includes(sensore.id) ? "none" : tipoSensori.includes(sensore.tipo) ? "none" : null }}>
                                        {sensore.nome} - {sensore.tipo}
                                    </option>
                                );
                            })}
                    </select><i style={{ fontSize: "90%", color: "#d3d3d3" }}>* {langSet.massimo1PerTipologia}</i>
                    <div style={{ overflow: "scroll", maxHeight: "25em" }}>
                        {sensoriSel.reverse().map((sensore, i) => (
                            <table id="Lista" style={{ border: 0 }} key={i}>
                                <tbody>
                                    <tr key={sensore}>
                                        <td width="12.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori.find((s) => s.id === sensore.id).nome}</td>
                                        <td width="12.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensore.id}</td>
                                        <td width="12.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori.find((s) => s.id === sensore.id).tipo}</td>
                                        <td width="7.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori.find((s) => s.id === sensore.id).stato}</td>
                                        <td width="7.5%" style={{ padding: 0, paddingLeft: ".5em" }}>{sensori.find((s) => s.id === sensore.id).batteria}</td>
                                        <td width="47.5%" style={{ padding: 0, paddingLeft: ".5em" }}>
                                            <div className="selettore selettoreFlex selettoreViolet" onClick={() => Sens("del", sensore)}>
                                                <p>{langSet.rimuovi}</p>
                                                <img src={delet} alt="delete" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ))}
                    </div>
                    <div className="navigators">
                        <div
                            className={defContinua}
                            onClick={() => addResidente()}
                        >
                            <p>{langSet.salvaEChiudi}</p>
                            <img src={confirmW} alt='arrow' />
                        </div>
                        <div
                            className="selettore selettoreFlex selettoreViolet"
                            onClick={() => setStatus(1)}
                        >
                            <p>{langSet.indietro}</p>
                            <img src={arrowFull} alt='arrow' />
                        </div>
                    </div>
                </div>
                
            </div >
        </>
    );
}

export default NuovoResidente;