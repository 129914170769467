

class GetStanza {
    constructor(json) {
        this.id = json.id;
        this.percorso = json.percorso;
        this.letti = json.letti;
    }
}


class AddStanza {
    constructor(id, descrizione, tipo, percorso, num_letti, id_thinkin) {
        this.id = id;
        this.descrizione = descrizione;
        this.tipo = tipo;
        this.percorso = percorso;
        this.num_letti = num_letti;
        this.id_thinkin = id_thinkin;
    }

    toJson() {
        return {
            id: this.id,
            descrizione: this.descrizione,
            tipo: this.tipo,
            percorso: this.percorso,
            num_letti: this.num_letti,
            id_thinkin: this.id_thinkin,
        };
    }
}

class GetSensoriLettoStanza {
    constructor(json) {
        this.stato = json.stato;
        this.residente = json.residente;
    }
}



export { GetStanza, AddStanza, GetSensoriLettoStanza };