import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import mqtt from 'mqtt';

import Griglia from '../../imgs/icons/griglia.svg';
import Lista from '../../imgs/icons/lista.svg';
import Mappa from '../../imgs/icons/mappa.svg';
import GrigliaW from '../../imgs/icons/grigliaW.svg';
import ListaW from '../../imgs/icons/listaW.svg';
import MappaW from '../../imgs/icons/mappaW.svg';
import arrowPurple from '../../imgs/icons/arrowPurple.svg';

import ResidenteComp from '../../components/Residente.js';
//import AmbienteComp from '../../components/Ambiente.js';
import PopupComp from '../../components/Popup.js';

import dashboardNode from 'node/dashboard/dashboard.js';

import lang from "../../lang.json";
const langSet = lang[localStorage.getItem("lang")].dashboard;

export default function Index(props) {
    const [accountData, setAccountData] = useState(JSON.parse(localStorage.getItem('account')));
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [sort, setSort] = useState('residente');
    const [order, setOrder] = useState('asc');
    const [residente, setResidente] = useState(null);

    let residenti = [];
    const [residentiState, setResidentiState] = useState([]);

    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }

    function vis(filtro) {
        props.Naviga("Dashboard", filtro);
    }

    function openPopup(category, data) {
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, data }]);
        setResidente(data);
    }

    useEffect(() => { //per catturare il cambio di struttura //non aggiorna, da aggiustare
        setAccountData(JSON.parse(localStorage.getItem('account')));
        console.log("accountData: " + accountData.mqtt_password);
        console.log(localStorage.getItem('account'));
    }, [localStorage.getItem('account')]);

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    class Residente {
        constructor(nome, id_stanza, percorso, sensori, id, allarme) {
            this.nome = nome;
            this.id_stanza = id_stanza;
            this.percorso = percorso;
            this.sensori = sensori;
            this.id = id;
            this.allarme = allarme;
        }
    }

    useEffect(() => {
        props.loading(true);
        setTimeout(() => {
            getDash();
            props.loading(false);
        }, 500);
    }, [localStorage.getItem('struttura')]);

    async function getDash() {
        props.loading(true);
        await dashboardNode.getDashboard(accountData.token, sort, order).then((res) => {
            if(!res.result) throw res.error;
            console.log(res.data)
            setDashboard(res.data);
        }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
            props.loading(false);
        });
    }

    useEffect(() => {
        if (dashboard !== null && dashboard !== undefined) {
            dashboard.map((res) => {
                if (res.residente !== null) {
                    let allarme = false;
                    res.sensori.map((sensore) => {
                        if (sensore.banner !== 0) {
                            allarme = true;
                        }
                    });
                    residenti.push(new Residente(res.residente, res.id_stanza, res.percorso, res.sensori, res.id_residente, allarme));
                }
            });
            setResidentiState(residenti);
            StreamData();
        }
    }, [dashboard]);

    useEffect(() => {
        if (residente !== null) {
            console.log(residente);
            let residenteX = residentiState.find((res) => res.id === residente.id);
            setInfosPopup([{ category: 'residente', data: residenteX }]);
        }
    }, [residentiState]);

    async function StreamData() {
        try {
            let allarme = false;
            //console.log(accountData.db);
            let prova = JSON.parse(localStorage.getItem('account'));
            console.log(prova.db);
            // refresh pagina!!!!

            const client = mqtt.connect('wss://dipas2.hype-design.it/mqtt', { username: prova.db, password: accountData.mqtt_password, protocol: 'mqtts', rejectUnauthorized: true, reconnectPeriod: 1000 });
            await new Promise((resolve) => client.once('connect', resolve));
            client.subscribe(`${accountData.db}/dashboard/#`);
            console.log('subscribed at ' + `${accountData.db}/dashboard/#`);
            client.on('message', async function (topic, message) {
                console.log(topic, JSON.parse(message.toString()));
                let xtopic = topic.split("/");
                setResidentiState(prevState => {
                    return prevState.map((residente) => {
                        if (residente.id === xtopic[3]) {
                            return {
                                ...residente,
                                sensori: residente.sensori.map((sensore) => {
                                    if (sensore.id === xtopic[4]) {
                                        JSON.parse(message.toString()).banner !== 0 ? allarme = true : allarme = false;
                                        return {
                                            ...sensore,
                                            stato: JSON.parse(message.toString()).stato,
                                            banner: JSON.parse(message.toString()).banner
                                        };
                                    } else {
                                        return sensore;
                                    }
                                }),
                                allarme: allarme
                            };
                        } else {
                            return residente;
                        }
                    });
                });
            });
        } catch (e) {
            console.log(e);
        }
    }

    function sortResidenti() {
        switch (sort) {
            case 'residente':
                switch (order) {
                    case 'asc':
                        setOrder('desc');
                        break;
                    case 'desc':
                        setOrder('asc');
                        setSort('id_stanza');
                        break;
                    default:
                        break;
                }
                break;
            case 'id_stanza':
                switch (order) {
                    case 'asc':
                        setOrder('desc');
                        break;
                    case 'desc':
                        setOrder('asc');
                        setSort('residente');
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        document.getElementById("arrow").style.transform = order === 'asc' ? "rotate(0deg)" : "rotate(180deg)";
        getDash();
    }

    function SchedaInScheda(category, info, operation, data, previousPage) {
        setInfosPopup([{ category, info, operation, data, previousPage }]);
    }

    return (
        <>
            <div id="loading" />
            <div id="Popup">
                <div onClick={() => centraPopup()} id="options">
                    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}
                </div>
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} closePopup={closePopup} SchedaInScheda={SchedaInScheda} loading={props.loading} />
                    </div>
                </Draggable>
            </div>
            <div id="Header" >
                {<div className='headerSX'>
                    {props.filtro === 'lista' ? <input type="search" name="search" autoComplete="off" onChange={(e) => props.cerca(e.target.value)} placeholder={langSet.cerca} /> : <></>}
                </div>}
                <div className='headerDX'>
                    <div className={props.filtro === "griglia" ? 'selettoreRound visualizzazione' : 'selettoreRound'} onClick={() => vis("griglia")} key="griglia">
                        <img src={props.filtro === "griglia" ? GrigliaW : Griglia} alt='Griglia' id="griglia" />
                    </div>
                    <div className={props.filtro === "lista" ? 'selettoreRound visualizzazione' : 'selettoreRound'} onClick={() => vis("lista")} key="lista">
                        <img src={props.filtro === "lista" ? ListaW : Lista} alt='Lista' id="lista" />
                    </div>
                    <div className={props.filtro === "mappa" ? 'selettoreRound visualizzazione' : 'selettoreRound'} onClick={() => vis("mappa")} key="mappa">
                        <img src={props.filtro === "mappa" ? MappaW : Mappa} alt='Mappa' id="mappa" />
                    </div>
                </div>
            </div>
            <div id="ContentPage">

                {props.filtro !== "mappa" ? (
                    <>
                        {props.filtro === "lista" ?
                            <>
                                <div id="ListaResidenti">
                                    <table id="TitoliLista">
                                        <tbody>
                                            <tr>
                                                <td width="11.25%" className='selected'>{langSet.residente} &nbsp; <img src={arrowPurple} alt='arrowPurple' className='arrowTitoli' id="arrowResidente" /> </td>
                                                <td width="18.70%">{langSet.stanza} &nbsp; <img src={arrowPurple} alt='arrowPurple' className='arrowTitoli' id="arrowStanza" /> </td>
                                                <td width="17.25%">{langSet.posizione}</td>
                                                <td width="17.15%">{langSet.sensoreCaduta}</td>
                                                <td width="17.25%">{langSet.letto}</td>
                                                {/* <td width="18.40%">Stato della stanza</td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                    {residentiState.map((residente, index) => (
                                        <ResidenteComp
                                            residente={residente}
                                            key={index}
                                            visualizzazione={props.filtro}
                                            openPopup={openPopup}
                                            loading={props.loading}
                                        />
                                    ))}
                                </div>
                                <div id="ListaAmbienti">
                                    <table id="TitoliLista">
                                        <tbody>
                                            <tr>
                                                <td width="30%" className='selected'>{langSet.ambiente} &nbsp; <img src={arrowPurple} alt='arrowPurple' className='arrowTitoli' id="arrowAmbiente" /> </td>
                                                <td width="70%">{langSet.statoAmbiente}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* {stanze.map((stanza, index) => (
                                        <AmbienteComp
                                            stanza={stanza}
                                            key={index}
                                            visualizzazione={props.filtro}
                                            openPopup={openPopup}
                                        />
                                    ))} */}
                                </div>
                            </>
                            : <>
                                {residentiState.filter((allarme) => {
                                    return allarme.allarme === true;
                                }).map((residente, index) => (
                                    <ResidenteComp
                                        residente={residente}
                                        key={index}
                                        visualizzazione={props.filtro}
                                        openPopup={openPopup}
                                        loading={props.loading}
                                    />
                                ))}
                                <br />
                                <div style={{ display: "flex", height: "2.5em", flexDirection: "row", alignItems: "center", clear: "both" }}>
                                    <p >{langSet.ordinaPer} </p>
                                    <div className="selettore selettoreFlex mini-selettore selettoreViolet" onClick={() => sortResidenti()}>
                                        <p>{sort === "residente" ? langSet.residente : langSet.idStanza}</p> <img src={arrowPurple} alt='arrowPurple' id="arrow" />
                                    </div>
                                </div>
                                {residentiState.filter((allarme) => {
                                    return allarme.allarme === false;
                                }).map((residente, index) => (
                                    <ResidenteComp
                                        residente={residente}
                                        key={index}
                                        visualizzazione={props.filtro}
                                        openPopup={openPopup}
                                        loading={props.loading}
                                    />
                                ))}
                                {/* {stanze.map((stanza, index) => (
                                    <AmbienteComp
                                        stanza={stanza}
                                        key={index}
                                        visualizzazione={props.filtro}
                                        openPopup={openPopup}
                                    />
                                ))}
                                <div style={{ clear: "both" }}>PROVA FILTRO</div> */}
                            </>
                        }
                    </>
                ) : (
                    <iframe title="mappa" src="https://customer.thinkin.io/portal/lookupui/lookup/view/token/0f1b09545a74ce13d57d821008fbe1af?hideAssetsAfterInactivityMs=43200000" width="99%" height="98%" style={{ border: "1px solid transparent" }}></iframe>
                )
                }
            </div>
        </>
    );
}