import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import bell from '../imgs/icons/bell.svg';
import arrow from '../imgs/icons/arrow.svg';
import arrowPurple from '../imgs/icons/arrowPurple.svg';
import it from '../imgs/it.png';
import de from '../imgs/de.png';
import en from '../imgs/en.png';
import logout from '../imgs/icons/logout.svg';
import PopupComp from './Popup.js';

import authNode from 'node/auth/auth.js';
import struttureNode from "node/struttura/struttura.js";
import notificheNode from 'node/notifiche/notifiche.js';
import auth from 'node/auth/auth.js';

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].header;
const langAlarms = lang[localStorage.getItem("lang")].alarms;

export default function Header(props) {
    const struttura = localStorage.getItem("struttura");
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [strutture, setStrutture] = useState([]);
    const [strutturaSel, setStrutturaSel] = useState();
    const [infosPopup, setInfosPopup] = useState(['']);
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    if (props.title !== 'Sensori' && struttura === 'Magazzino generale') StrutturaSel(accountData.db);
    const [notifiche, setNotifiche] = useState(0);

    let perm = accountData.tipo;
    if (perm === 0) perm = "Admin";
    else if (perm === 1) perm = langSet.itInterno;
    else if (perm === 2) perm = langSet.manutEsterno;
    else if (perm === 3) perm = langSet.coordinatoreInfermiere;
    else if (perm === 4) perm = langSet.personaleDiCorsia;

    function Logout() {
        localStorage.removeItem("login");
        localStorage.removeItem("account");
        localStorage.removeItem("struttura");
        window.location.href = "/";
    }

    function openSottoSel(id) {
        let sottoSel = document.getElementById(id);
        if (sottoSel.style.opacity === '0') {
            sottoSel.style.display = "block";
            setTimeout(() => {
                sottoSel.style.opacity = 1;
            }, 0);
        } else {
            sottoSel.style.opacity = 0;
            setTimeout(() => {
                sottoSel.style.display = "none";
            }, 200);
        }
    }

    async function getStrutture() {
        await struttureNode.getListaStrutture(accountData.token).then((res) => {
            if (res.result) {
                setStrutture(res.data);
            }
        });
    }

    async function StrutturaSel(strutturaNew) {
        if (strutturaNew !== "Magazzino generale" && strutturaNew !== struttura ) switchStruttura(strutturaNew); //strutturaNew !== struttura è un controllo per il cambio struttura, altrimenti viene eseguitro due volte
        setStrutturaSel(strutturaNew);
        localStorage.setItem("struttura", strutturaNew);
        if (props.title === 'Sensori' && strutturaNew !== struttura) props.refreshSensori();
        if (props.title === 'Struttura') props.refreshStruttura();
        if (props.title === 'Utenti') props.refreshUtenti();
        if (props.title === 'Notifiche') props.refreshNotifiche();
        if (props.title === 'Residenti') props.refreshResidenti();
        if (props.title === 'Dashboard') props.refreshDashboard();
        await notificheNode.getNotifiche(accountData.token).then((res) => {
            if(res.data.length > 0) setNotifiche(1);
            else setNotifiche(0);
         })
    }

    useEffect(() => {
        StrutturaSel(localStorage.getItem("struttura"));
        if (accountData.tipo === 0) getStrutture();
    }, [struttura]);

    useEffect(() => {
        if (strutturaSel === "null") {
            openPopup("alarm", langAlarms.erroreStruttura, "logout");
        }
    }, [strutturaSel]);

    function Sloggah() {
        openPopup("alarm", langAlarms.logoutAutomatico, "logout");
    }
    async function switchStruttura(id) {
        await authNode.switchStruttura(accountData.token, id).then((res) => {
            let account = JSON.parse(localStorage.getItem("account")); //prendo i dati dell'account dal local storage
            account.mqtt_password = res.message; //aggiorno la password mqtt
            account.db = id; //aggiorno il db
            localStorage.setItem("account", JSON.stringify(account)); //salvo i dati dell'account nel local storage
            console.log("Password mqtt aggiornata: " + localStorage.getItem("account")); //da controllare! 
        });
    }

    function openPopup(category, info, operation, data) {
        document.getElementById("PopupHeader").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("PopupHeader").style.display = "none";
        setInfosPopup(['']);
    }

    function changeLang(lang) {
        localStorage.setItem("lang", lang);
        setLang(lang);
        window.location.reload();
    }

    return (
        <>
            <div id="PopupHeader">
                <Draggable handle="#handle" position={{ x: 0, y: 0 }}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} Logout={Logout} closePopup={closePopup} />
                    </div>
                </Draggable>
            </div>
            <div className='headerSX'>{langSet[props.title.toLowerCase()]}</div>
            {accountData.tipo === 0 ?
                <>
                    <div className='headerCX'>
                        <div className='selettore selettoreFlex' style={{ position: "absolute", left: "45%" }} onClick={() => openSottoSel("struttura")}>
                            <p>{strutturaSel === null ? "Seleziona struttura" : strutturaSel}</p>&nbsp;
                            <img src={arrow} alt='arrow' />
                            <div className='sottoSelettore' id="struttura" style={{ fontSize: "85%" }}>
                                {props.title === 'Sensori' ?
                                    <div id="Lista" className='row' onClick={() => StrutturaSel("Magazzino generale")}>
                                        <p>Magazzino generale</p>
                                    </div>
                                    :
                                    null
                                }
                                {strutture.map((struttura, i) => {
                                    return (
                                        <div id="Lista" className='row' key={i} onClick={() => StrutturaSel(struttura.id)}>
                                            <p>{struttura.nome}</p>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    </div>
                </> :
                <>
                </>
            }
            {/* <div className='headerCX'>
                <div className='selettore selettoreFlex selettoreViolet' style={{ width: "auto" }} onClick={() => openSottoSel("struttura")}>
                    <p>{strutturaSel === null ? "Seleziona struttura" : strutturaSel}</p>&nbsp;
                    <img src={arrowPurple} alt='arrow' />
                    <div className='sottoSelettore' id="struttura" style={{ fontSize: "85%" }}>
                        <div id="Lista" className='row' onClick={() => StrutturaSel("Magazzino generale")}>
                            <p>Magazzino generale</p>
                        </div>
                        {accountData.tipo === 0 ? (
                            <>
                                {strutture.map((struttura, i) => {
                                    return (
                                        <div id="Lista" className='row' key={i} onClick={() => StrutturaSel(struttura)}>
                                            <p>{struttura}</p>
                                        </div>
                                    );
                                })};
                            </>
                        ) : <></>}
                    </div>
                </div>
            </div> */}
            <div className='headerDX' >
                    <img src={bell} className='bell' alt='Notifiche' onClick={() => props.Naviga("Notifiche")} />
                    {
                        notifiche === 1 ?
                            <div className="dot red" style={{ position: "absolute", top:".5em", left: "1em" }} /> : null
                    }
                <div className='selettore selettoreFlex selettoreHeader' onClick={() => openSottoSel("account")}>
                    <div>
                        <p style={{ fontSize: "70%", color: "#A8B1CE" }}>{perm}</p>
                        <p style={{ marginRight: "1em" }}>{accountData.cognome + " " + accountData.nome}</p>
                    </div>
                    <img src={arrow} alt='arrow' className='arrow' />
                    <div className='sottoSelettore' id="account" onClick={() => Sloggah()}>
                        <div className='row'>
                            <p className='logout'>Logout</p>
                            <img src={logout} alt='logout' />
                        </div>
                    </div>
                </div>
                <div className='selettore selettoreFlex selettoreHeader' style={{ width: "4em" }} onClick={() => openSottoSel("lang")}>
                    <p>
                        {localStorage.getItem("lang") === "de" ?
                            <img src={de} alt="de" className='flag' /> :
                            localStorage.getItem("lang") === "en" ?
                                <img src={en} alt="en" className='flag' /> :
                                <img src={it} alt="it" className='flag' /> 
                        }
                    </p>
                    <img src={arrow} alt='arrow' className='arrow' />
                    <div className='sottoSelettore' id="lang">
                        {localStorage.getItem("lang") === "it" ?
                            <>
                                <div className='row' onClick={() => changeLang("de")}>
                                    <p><img src={de} alt='de' className='flag' /></p>
                                    <p>DE</p>
                                </div>
                                <div className='row' onClick={() => changeLang("en")}>
                                    <p><img src={en} alt='en' className='flag' /></p>
                                    <p>EN</p>
                                </div>
                            </> :
                            localStorage.getItem("lang") === "de" ?
                                <>
                                    <div className='row' onClick={() => changeLang("it")}>
                                        <p><img src={it} alt='it' className='flag' /></p>
                                        <p>IT</p>
                                    </div>
                                    <div className='row' onClick={() => changeLang("en")}>
                                        <p><img src={en} alt='en' className='flag' /></p>
                                        <p>EN</p>
                                    </div>
                                </> :
                                localStorage.getItem("lang") === "en" ?
                                    <>
                                        <div className='row' onClick={() => changeLang("it")}>
                                            <p><img src={it} alt='it' className='flag' /></p>
                                            <p>IT</p>
                                        </div>
                                        <div className='row' onClick={() => changeLang("de")}>
                                            <p><img src={de} alt='de' className='flag' /></p>
                                            <p>DE</p>
                                        </div>
                                    </> : null

                        }
                    </div>
                </div>
            </div>
        </>
    );
}