import axios from 'axios';
import * as Crypto from '../crypt/crypt.js';
import * as Corridoio from './corridoi_model.js';
import * as Auth from '../auth/auth.js';

/*--------------------- AGGIUNGO UN CORRIDOIO ---------------------*/
async function addCorridoio(token, id, descrizione, id_thinkin, id_ala) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const nuovoCorridoio = new Corridoio.AddCorridoio(id, descrizione, id_thinkin, id_ala);

        const response = await axios.post(`${Auth.default.url}/corridoi`, Crypto.encrypt(JSON.stringify(nuovoCorridoio.toJson())), { headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data.toString()) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- ELIMINO CORRIDOIO ---------------------*/
async function deleteCorridoio(token, id_corridoio) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/corridoi/${id_corridoio}`, { headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function putStanzainCorridoio(token, id_corridoio, id_stanza) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.put(`${Auth.default.url}/corridoi/${id_corridoio}/stanze/${id_stanza}`, {}, { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };

    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function deleteStanzainCorridoio(token, id_corridoio, id_stanza) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/corridoi/${id_corridoio}/stanze/${id_stanza}`, { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function putSensoreinCorridoio(token, id_corridoio, id_sensore) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.put(`${Auth.default.url}/corridoi/${id_corridoio}/sensori/${id_sensore}`, {}, { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function deleteSensoreinCorridoio(token, id_corridoio, id_sensore) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/corridoi/${id_corridoio}/sensori/${id_sensore}`, { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };

    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

async function editDescrizione(token, id_corridoio, desc) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.patch(`${Auth.default.url}/corridoi/${id_corridoio}`, Crypto.encrypt(desc), { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

export default { addCorridoio, deleteCorridoio, putSensoreinCorridoio, deleteSensoreinCorridoio, putStanzainCorridoio, deleteStanzainCorridoio, editDescrizione };