import axios from 'axios';
import * as Magazzino from './magazzino_generale_module.js';
import * as Crypto from '../crypt/crypt.js';
import * as Auth from '../auth/auth.js';


/*--------------------- OTTENGO IL MAGAZZINO GENERALE ---------------------*/
async function getMagazzinoGenerale(token, sort, orderby) {
    try {
        const headers = { 'Authorization': `Bearer ${token}` };

        let response = await axios.get(`${Auth.default.url}/magazzino_generale/all`, { params: { sortby: sort, orderby: orderby }, headers });
        
        let sensori = JSON.parse(Crypto.decrypt(response.data));

        let data = [];

        for (let sensore of sensori) {
            data.push(new Magazzino.GetMagazzino(sensore));
        }

        return { result: true, data: data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- AGGIUNGO SENSORE AL MAGAZZINO GENERALE ---------------------*/
async function addSensoreGenerale(token, id, nome, tipo, modello) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const nuovoSensore = new Magazzino.AddSensore(modello, tipo, nome, id);
        
        const response = await axios.post(`${Auth.default.url}/magazzino_generale`, Crypto.encrypt(JSON.stringify(nuovoSensore.toJson())), { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}


/*--------------------- SPOSTO SENSORE DAL MAGAZZINO GENERALE A MAGAZZINO STRUTTURA ---------------------*/
async function patchSensoreMagazzinoGenerale(token, id_sensore) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.patch(`${Auth.default.url}/magazzino_generale/${id_sensore}/magazzino`, {}, { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}


/*--------------------- ELIMINO SENSORE DAL MAGAZZINO GENERALE ---------------------*/
async function deleteSensoreMagazzinoGenerale(token, id_sensore) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/magazzino_generale/${id_sensore}`, { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

export default { getMagazzinoGenerale, addSensoreGenerale, patchSensoreMagazzinoGenerale, deleteSensoreMagazzinoGenerale };