

class GetMagazzino {
    constructor(json) {
        this.id = json.id;
        this.nome = json.nome;
        this.tipo = json.tipo;
        this.quarantena = json.quarantena;
    }
}

/*--------------------- CLASSE AGGIUNGO SENSORE ---------------------*/
class AddSensore {
    constructor(modello, tipo, nome, id) {
        this.nome = nome;
        this.tipo = tipo;
        this.modello = modello;
        this.id = id;
    }
    toJson() {
        return {
            nome: this.nome,
            tipo: this.tipo,
            modello: this.modello,
            id: this.id
        }
    }
}

export { GetMagazzino, AddSensore };