import axios from 'axios';
import * as Crypto from '../crypt/crypt.js';
import * as Stanze from './stanze_model.js';
import * as Auth from '../auth/auth.js';



/*--------------------- OTTENGO LA STANZA DEL RESIDENTE ---------------------*/

async function getStanza(token, id_stanza) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/stanze/${id_stanza}`, { headers: headers });

    let stanza = new Stanze.GetStanza(JSON.parse(Crypto.decrypt(response.data)));

    return { result: true, data: stanza };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function getStanzeLibere(token) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };
    let response = await axios.get(`${Auth.default.url}/stanze/libere`, { headers: headers });

    return { result: true, data: JSON.parse(Crypto.decrypt(response.data)) };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: e.response.data.toString() };
    } else{
      return { result: false, error: e.toString() };
    }
  }
}

async function addStanza(token, id, descrizione, tipo, percorso, num_letti, id_thinkin) {
  try {
    const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

    const nuovaStanza = new Stanze.AddStanza(id, descrizione, tipo, percorso, num_letti, id_thinkin);

    const response = await axios.post(`${Auth.default.url}/stanze`, Crypto.encrypt(JSON.stringify(nuovaStanza.toJson())), { headers: headers });

    return { result: true, message: Crypto.decrypt(response.data) };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

async function getSensoriLettoStanza(token, id_stanza) {
  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    let response = await axios.get(`${Auth.default.url}/stanze/${id_stanza}/sensori_letto`, { headers: headers });

    let sensori = JSON.parse(Crypto.decrypt(response.data));

    let data = [];

    for(let sensore of sensori) {
      data.push(new Stanze.GetSensoriLettoStanza(sensore));
    }

    return { result: true, data: data };
  } catch (e) {
    if (axios.AxiosError && e.response) {
      return { result: false, error: Crypto.decrypt(e.response.data) };
    } else {
      return { result: false, error: e.toString() };
    }
  }
}

export default { getStanza, getStanzeLibere, addStanza, getSensoriLettoStanza };