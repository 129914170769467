import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';

import addW from '../../imgs/icons/addW.svg';
import delet from '../../imgs/icons/delete.svg';
import arrowPurple from '../../imgs/icons/arrowPurple.svg';

import PopupComp from '../../components/Popup.js';
import residentiNode from 'node/residenti/residenti.js';

import lang from "../../lang.json";
const langSet = lang[localStorage.getItem("lang")].residenti;
const langAlarms = lang[localStorage.getItem("lang")].alarms;

export default function Index(props) {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);
    const [residenti, setResidenti] = useState([]);
    const [sort, setSort] = useState('cognome');
    const [order, setOrder] = useState('asc');

    async function Sort(sort) {
        setSort(sort);

        let sortBy = sort;
        let orderBy = '';
        let arrows = document.getElementsByClassName('arrowTitoli');
        if (order === 'asc') {
            setOrder('desc');
            orderBy = 'desc';
            for (let i = 0; i < arrows.length; i++) {
                arrows[i].style.transform = "rotate(180deg)";
            }
        } else {
            for (let i = 0; i < arrows.length; i++) {
                arrows[i].style.transform = "rotate(0deg)";
            }
            orderBy = 'asc';
            setOrder('asc');
        }
        await getRes(sortBy, orderBy);
    }

    async function getRes(sortBy, orderBy) {
        props.loading(true);
        await residentiNode.getResidenti(accountData.token, sortBy, orderBy).then((res) => {
            if(!res.result) throw res.error;
            setResidenti(res.data);
        }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
            props.loading(false);
        });
    }

    useEffect(() => {
        props.loading(true);
        setTimeout(() => {
            getRes(sort, order);
            props.loading(false);
        }, 500);
    }, [localStorage.getItem('struttura')]);

    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }

    function openPopup(category, info, operation, data) {
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    return (
        <>
            <div id="loading" />
            <div id="Popup">
                <div onClick={() => centraPopup()} id="options">
                    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}
                </div>
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} getRes={getRes} loading={props.loading} closePopup={closePopup} />
                    </div>
                </Draggable>
            </div>
            <div id="Header" >
                <div className='headerSX'>
                    <input type="search" name="search" autoComplete="off" onChange={(e) => props.cerca(e.target.value)} placeholder={langSet.cerca} />
                </div>
                <div className='headerDX'>
                    <div className='selettore selettorePurple selettoreFlex' style={{ width: "12.5em" }} onClick={() => openPopup("nuovo_residente")}>
                        <p>{langSet.aggiungiResidente}</p>
                        <img src={addW} alt='add' />
                    </div>
                </div>
            </div>
            <div id="ContentPage">
                <table id="TitoliLista">
                    <tbody>
                        <tr>
                            <td width="17.25%" className={sort === 'cognome' ? 'selected' : null} onClick={() => Sort('cognome')}>{langSet.residente} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' /> </td>
                            <td width="17.25%" className={sort === 'percorso' ? 'selected' : null} onClick={() => Sort('percorso')}>{langSet.posizione} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' /> </td>
                            <td width="11.25%" className={sort === 'stanza' ? 'selected' : null} onClick={() => Sort('stanza')}>{langSet.nStanza} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' /> </td>
                            <td width="17.25%" className={sort === 'registrazione' ? 'selected' : null} onClick={() => Sort('registrazione')}>{langSet.registrazione} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' /> </td>
                            <td width="37%"></td>
                        </tr>
                    </tbody>
                </table>
                {residenti === undefined ?
                    <div id="noData">
                        <p>{langSet.erroreCaricamento}</p>
                    </div>
                    : residenti.map((residente, i) => (
                        <table id="Lista" key={i} className='searchHere'>
                            <tbody>
                                <tr>
                                    <td width="17.25%">
                                        {residente.cognome} {residente.nome}
                                    </td>
                                    <td width="17.25%">
                                        {residente.percorso}
                                    </td>
                                    <td width="11.25%">
                                        {residente.stanza}
                                    </td>
                                    <td width="17.25%">
                                        {residente.registrazione}
                                    </td>
                                    <td width="37%" align="right">
                                        <div className='selettore selettoreViolet selettoreFlex' onClick={() => openPopup("alarm", langAlarms.ilResidente + residente.cognome + " " + residente.nome + langAlarms.verraArchiviato, "deleteResidente", residente)}>
                                            <p>{langSet.archivia}</p>
                                            <img src={delet} alt='delete' />
                                        </div>
                                        <div className='selettore selettoreBorder selettoreFlex' onClick={() => openPopup("scheda_residente", residente)}>
                                            <p>{langSet.schedaResidente}</p>
                                            <img src={arrowPurple} alt='arrow' style={{ transform: "rotate(-90deg)" }} />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ))}
            </div>
        </>
    );
}