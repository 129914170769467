import crypto from 'crypto-js';

const originkey = crypto.enc.Utf8.parse('&aKQX{)7Mpn!?6BUfG&9J[Rd5frFnm]#');
const iv = crypto.enc.Utf8.parse('yXRvg#V#7/38+aQb');

function decrypt(text) {
    
    const key = originkey;
    
    const ivvar = iv;
    
    const encryptedStringHex = crypto.AES.decrypt(text, key, {iv: ivvar, mode: crypto.mode.CBC, padding: crypto.pad.Pkcs7, format: crypto.format.Hex}).toString(crypto.enc.Utf8);
    
    return encryptedStringHex;
}

/*--------------------- CLASSE LOGIN POST---------------------*/
class LoginModule {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }
    toJson() {
        return {
            email: this.email,
            password: this.password
        };
    }
}


/*--------------------- CLASSE TOKEN RESPONSE LOGIN ---------------------*/
class TokenModule {
    constructor(json) {
        this.uid = json.uid;
        this.token = json.token;
        this.email = json.email;
        this.nome = json.nome;
        this.cognome = json.cognome;
        this.db = json.db;
        this.tipo = json.tipo;
        this.mqtt_password = json.mqtt_password ? decrypt(json.mqtt_password) : undefined;
    }
}



/*--------------------- CLASSE REGISTRAZIO UTENTE ---------------------*/
class SignUpModule {
    constructor(nome, cognome, email, password, tipo) {
        this.nome = nome;
        this.cognome = cognome;
        this.email = email;
        this.password = password;
        this.tipo = tipo;
    }
    toJson() {
        return {
            nome: this.nome,
            cognome: this.cognome,
            email: this.email,
            password: this.password,
            tipo: this.tipo
        };
    }
}



class Utente {
    constructor(json) {
        this.id = json.id;
        this.cognome = json.cognome;
        this.db = json.db;
        this.email = json.email;
        this.nome = json.nome;
        this.tipo = json.tipo;
        this.registrazione = json.registrazione;
    }
}

export { TokenModule, LoginModule, SignUpModule, Utente };
