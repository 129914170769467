import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import addW from '../../imgs/icons/addW.svg';
import arrowPurple from '../../imgs/icons/arrowPurple.svg';
import delet from '../../imgs/icons/delete.svg';

import PopupComp from '../../components/Popup.js';
import sensoriNode from 'node/sensori/sensori.js';
import magazzinoGeneraleNode from 'node/magazzino-generale/magazzino_generale.js';

import lang from "../../lang.json";
const langSet = lang[localStorage.getItem("lang")].sensori;
const langAlarms = lang[localStorage.getItem("lang")].alarms;

export default function Index(props) {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const struttura = localStorage.getItem('struttura');
    const [sort, setSort] = useState('nome');
    const [order, setOrder] = useState('asc');
    const [sensori, setSensori] = useState([]);
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);

    async function Sort(sorting) {
        props.loading(true);
        setSort(sorting);
        let sortBy = sorting;
        let orderBy = '';
        let arrow = document.getElementById(`Arrow${sorting}`);
        if (order === 'desc') {
            orderBy = 'asc';
            setOrder('asc');
            arrow.style.transform = "rotate(0deg)";
        } else {
            orderBy = 'desc';
            setOrder('desc');
            arrow.style.transform = "rotate(180deg)";
        }
        await getSens(sortBy, orderBy);
    }

    async function getSens(sorting, ordeBy) {
        props.loading(true);
        if (struttura === 'Magazzino generale') {
            await magazzinoGeneraleNode.getMagazzinoGenerale(accountData.token, sorting, ordeBy).then((response) => {
                if(!response.result) throw response.error;
                setSensori(response.data);
            }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
                props.loading(false);
            });
        } else {
            await sensoriNode.getSensori(accountData.token, sorting, ordeBy).then((response) => {
                if(!response.result) throw response.error;
                setSensori(response.data);
            }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
                props.loading(false);
            });
        }
    }

    useEffect(() => {
        props.loading(true);
        setTimeout(() => {
            getSens();
            props.loading(false);
        }, 500);
    }, [localStorage.getItem('struttura')]);


    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }

    function openPopup(category, info, operation, data) {
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    function SchedaInScheda(category, info, operation, data, previousPage) {
        setInfosPopup([{ category, info, operation, data, previousPage }]);
    }

    return (
        <>
            <div id="loading" />
            <div id="Popup">
                <div onClick={() => centraPopup()} id="options">
                    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}
                </div>
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} getSens={getSens} closePopup={closePopup} loading={props.loading} SchedaInScheda={SchedaInScheda} />
                    </div>
                </Draggable>
            </div>
            <div id="Header" >
                <div className='headerSX'>
                    <input type="search" name="search" autoComplete="off" onChange={(e) => props.cerca(e.target.value)} placeholder={langSet.cerca} />
                </div>
                <div className='headerDX'>
                    <div className='selettore selettoreFlex selettorePurple' style={{ width: "12.5em" }} onClick={() => openPopup('nuovo_sensore', '', 'addSensore', '')}>
                        <p>{langSet.aggiungiSensore}</p>
                        <img src={addW} alt='add' />
                    </div>
                </div>
            </div>
            <div id="ContentPage">
                <table id="TitoliLista">
                    <tbody>
                        <tr>
                            <td width="12.5%" className={sort === 'nome' ? 'selected' : null} onClick={() => Sort('nome')}>{langSet.nome} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrownome' /> </td>
                            <td width="17.5%" >ID </td>
                            <td width="10%" className={sort === 'tipo' ? 'selected' : null} onClick={() => Sort('tipo')}>{langSet.tipo} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrowtipo' /> </td>
                            <td width="5%" className={sort === 'stato' ? 'selected' : null} onClick={() => Sort('stato')}>{langSet.stato} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrowstato' /> </td>
                            <td width="18%">{langSet.posizione}</td>
                            <td width="7%" className={sort === 'batteria' ? 'selected' : null} onClick={() => Sort('batteria')}>{langSet.batteria} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrowbatteria' /> </td>
                            <td width="30%"></td>
                        </tr>
                    </tbody>
                </table>

                {sensori === undefined ?
                    <div id="noData">
                        <p>{langSet.erroreCaricamento}</p>
                    </div>
                    : sensori.map((sensore, index) => {
                        return (
                            <table id="Lista" key={index}>
                                <tbody>
                                    <tr>
                                        <td width="12.5%">{sensore.nome}</td>
                                        <td width="17.5%">{sensore.id}</td>
                                        <td width="10%">{sensore.tipo}</td>
                                        <td width="5%">{sensore.stato}</td>
                                        <td width="18%" style={{ fontSize: "90%" }}>{sensore.percorso}</td>
                                        <td width="7%">{sensore.batteria}</td>
                                        <td width="30%">
                                            <div className='selettore selettoreFlex selettoreViolet' onClick={() => (struttura !== 'Magazzino generale' ? openPopup("alarm", langAlarms.ilSensore + sensore.nome + " - " + sensore.id + langAlarms.verraSpostato, "deleteSensore", sensore) : openPopup("alarm", langAlarms.ilSensore + sensore.nome + " - " + sensore.id + langAlarms.verraEliminato, "deleteSensoreMagazzinoGen", sensore))}>
                                                <p>{langSet.elimina}</p>
                                                <img src={delet} alt='delete' />
                                            </div>
                                            <div className='selettore selettoreFlex selettoreBorder' onClick={() => openPopup("scheda_sensore", sensore)}>
                                                <p>{langSet.schedaSensore}</p>
                                                <img src={arrowPurple} alt='arrow' style={{ transform: "rotate(-90deg)" }} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    })}
            </div>
        </>
    );
}