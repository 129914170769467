import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import arrowPurple from '../../imgs/icons/arrowPurple.svg';

import PopupComp from '../../components/Popup.js';

import notificheNode from 'node/notifiche/notifiche.js';

import lang from "../../lang.json";
const langSet = lang[localStorage.getItem("lang")].notifiche;

export default function Index(props) {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [sort, setSort] = useState('creato');
    const [order, setOrder] = useState('asc');
    const [notifiche, setNotifiche] = useState([]);
    const [infosPopup, setInfosPopup] = useState(['']);
    const [dragPosition, setDragPosition] = useState(null);
    const [page, setPage] = useState(0);

    async function Sort(sorting) {
        props.loading(true);
        setSort(sorting);
        let sortBy = sorting;
        let orderBy = '';
        let arrow = document.getElementById(`Arrow${sorting}`);
        if (order === 'desc') {
            orderBy = 'asc';
            setOrder('asc');
            arrow.style.transform = "rotate(0deg)";
        } else {
            orderBy = 'desc';
            setOrder('desc');
            arrow.style.transform = "rotate(180deg)";
        }
        await getNot(sortBy, orderBy);
    }

    async function getNot(sorting, orderBy) {
        props.loading(true);
        await notificheNode.getNotifiche(accountData.token, sorting, orderBy).then((res) => {
            if(!res.result) throw res.error;
            setNotifiche(res.data);
        }).catch((e) => openPopup('alarm', e.toString())).finally(() => {
            props.loading(false);
        });
    }

    useEffect(() => {
        props.loading(true);
        setTimeout(() => {
            setSort(props.sortBy);
            setOrder(props.orderBy);
            getNot(props.sortBy, props.orderBy);
            props.loading(false);
        }, 500);
    }, [localStorage.getItem('struttura')]);

    function centraPopup() { dragPosition === null ? setDragPosition({ x: 0, y: 0 }) : setDragPosition(null); }

    function openPopup(category, info, operation, data) {
        document.getElementById("Popup").style.display = "flex";
        setInfosPopup([{ category, info, operation, data }]);
    }

    function closePopup() {
        document.getElementById("Popup").style.display = "none";
    }

    function SchedaInScheda(category, info, operation, data, previousPage) {
        setInfosPopup([{ category, info, operation, data, previousPage }]);
    }

    return (
        <>
            <div id="loading" />
            <div id="Popup">
                <div onClick={() => centraPopup()} id="options">
                    {dragPosition === null ? "CENTRA POPUP" : "LIBERA POPUP"}
                </div>
                <Draggable handle="#handle" position={dragPosition}>
                    <div className="popupContent">
                        <PopupComp info={infosPopup} getNot={getNot} closePopup={closePopup} loading={props.loading} SchedaInScheda={SchedaInScheda} />
                    </div>
                </Draggable>
            </div>
            <div id="Header" >
                <div className='headerSX'>
                    <input type="search" name="search" autoComplete="off" onChange={(e) => props.cerca(e.target.value)} placeholder={langSet.cerca} />
                </div>
                {notifiche ?
                    <>
                        <div className='headerDX'>
                            <div className='selettore selettoreFlex selettoreViolet'>
                                <img
                                    src={arrowPurple}
                                    style={{ transform: "rotate(90deg)" }}
                                    onClick={() => page > 0 ? setPage(page - 1) : null}
                                />
                                <div style={{ fontWeight: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>{langSet.pagina}&nbsp;{page + 1} / <p onClick={() => setPage(Math.ceil(notifiche.length / 30) - 1)}>{Math.ceil(notifiche.length / 30)}</p></div>
                                <img
                                    src={arrowPurple}
                                    style={{ transform: "rotate(-90deg)" }}
                                    onClick={() => page < Math.ceil(notifiche.length / 30) - 1 ? setPage(page + 1) : null}
                                />
                            </div>
                        </div>
                    </> :
                    <>
                    </>
                }

            </div>
            <div id="ContentPage">
                <table id="TitoliLista">
                    <tbody>
                        <tr>
                            <td width="25%" className={sort === 'messaggio' ? 'selected' : null} onClick={() => Sort('messaggio')}>{langSet.messaggio} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrowmessaggio' /> </td>
                            <td width="12.5%" >{langSet.tipo} </td>
                            <td width="12.5%" >{langSet.nomeDispositivo} </td>
                            <td width="17.5%" className={sort === 'residente' ? 'selected' : null} onClick={() => Sort('residente')}>{langSet.residente} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrowresidente' /> </td>
                            <td width="12.5%" >{langSet.stanza} </td>
                            <td width="12.5%" className={sort === 'creato' ? 'selected' : null} onClick={() => Sort('creato')}>{langSet.creatoIl} &nbsp; <img src={arrowPurple} alt='arrow' className='arrowTitoli' id='Arrowcreato' /> </td>
                            <td width="5%">{langSet.conferma}</td>
                        </tr>
                    </tbody>
                </table>
                {notifiche === undefined ?
                    <div id="noData">
                        <p>{langSet.erroreCaricamento}</p>
                    </div>
                    : notifiche.slice(page * 30, (page + 1) * 30).map((notifica, index) => {
                        return (
                            <table id="Lista" key={index} className={notifica.confermata ? "" : "allarme"} style={{ cursor: "pointer" }} onClick={() => openPopup('scheda_notifica', notifica)}>
                                <tbody>
                                    <tr>
                                        <td width="25%">{notifica.messaggio}</td>
                                        <td width="12.5%">{notifica.tipo}</td>
                                        <td width="12.5%">{notifica.id_sensore}</td>
                                        <td width="17.5%">{notifica.residente}</td>
                                        <td width="12.5%">{notifica.id_stanza}</td>
                                        <td width="12.5%">{notifica.creato}</td>
                                        <td width="5%">
                                            <div className={notifica.confermata ? 'dot green big' : 'dot red big'} style={{ right: "-1.75em" }}>&nbsp;</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    })}
            </div>
        </>
    );
}