import React from 'react';

import lang from "../lang.json";
if (localStorage.getItem("lang") === null) {
    localStorage.setItem("lang", "it")
}
const langSet = lang[localStorage.getItem("lang")].menubar;

const Menu = (props) => {
    let x ;
    function openOpt(title) {
        if(title !== props.sel) props.Naviga(title);
    }

    return (
        <>
            {props.items.map((item, i) => (
                <div className={props.sel === item[0] ? "menuItem menuItemActive" : "menuItem"} onClick={() => openOpt(item[0])} key={i} >
                <img src={props.sel === item[0] ? item[1][1] : item[1][0]} alt="icon" />
                    <p>{langSet[item[0].toLowerCase()]}</p>
                </div>
            ))}
        </>
    );
}

export default Menu;