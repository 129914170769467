import React, { useEffect, useState } from "react";
import bed from "../imgs/icons/bed.svg";
import alarmR from "../imgs/icons/alarmR.svg";
import alarmY from "../imgs/icons/alarmY.svg";
import userB from "../imgs/icons/userB.svg";
import position from "../imgs/icons/position.svg";
import caduta from "../imgs/icons/caduta.svg";
import offline from "../imgs/icons/offline.svg";

import stanzeNode from "node/stanze/stanze.js";

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].dashboard;

const Residente = (props) => {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const stanza = [];

    let sensoreLetto = props.residente.sensori.find((item) => item.tipo === "Sensore letto");
    if (sensoreLetto === undefined) { sensoreLetto = { tipo: "Sensore letto", stato: "Offline", banner: -1 }; }

    let sensoreGeofencing = props.residente.sensori.find((item) => item.tipo === "Sensore geofencing");
    if (sensoreGeofencing === undefined) { sensoreGeofencing = { tipo: "Sensore geofencing", stato: "Offline", banner: -1 }; }

    let sensoreCaduta = props.residente.sensori.find((item) => item.tipo === "Sensore caduta");
    if (sensoreCaduta === undefined) { sensoreCaduta = { tipo: "Sensore caduta", stato: "Offline", banner: -1 }; }

    let sensoreTemperatura = props.residente.sensori.find((item) => item.tipo === "Sensore temperatura");
    if (sensoreTemperatura === undefined) { sensoreTemperatura = { tipo: "Sensore temperatura", stato: "Offline", banner: -1 }; }

    class Stanza {
        constructor(id_stanza, id_piano, posizione) {
            this.id_stanza = id_stanza;
            this.id_piano = id_piano;
            this.posizione = posizione;
        }
    }

    async function getStanze(id_stanza) {
        props.loading(true);
        await stanzeNode.getStanza(accountData.token, id_stanza).then((res) => {
            stanza.push(new Stanza(res.data.id_stanza, res.data.id_piano, res.data.posizione));
        }).finally(() => {
            props.loading(false);
        });
    }

    useEffect(() => {
        getStanze(props.residente.id_stanza);
    }, []);

    return (
        <>
            {props.visualizzazione === "griglia" ?
                <div
                    id={props.residente.allarme ? "GrigliaAlarm" : "Griglia"}
                    /* style={{ background: "linear-gradient(180deg, #FFFFFF 83.5%, #F2F5FF 83.5%)" }} RIATTIVARE PER TEMPERATURA STANZA*/
                    onClick={() => props.openPopup("residente", props.residente)}
                >
                    <table>
                        <tbody>
                            <tr>
                                <td width="20%">
                                    <img src={userB} alt='user' />
                                </td>
                                <td width="80%" style={{ fontSize: "1.15em" }}>
                                    <div style={{ width: "7em", paddingLeft: ".25em" }}>
                                        {props.residente.nome}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ fontSize: "80%", color: "#6A6E83", width: "100%" }}>
                                    {props.residente.percorso}
                                </td>
                            </tr>
                            <tr>
                                <td width="10%" style={{ fontWeight: "600" }}>
                                    {props.residente.id_stanza}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{ borderCollapse: "inherit" }}>
                        <tbody>
                            <tr>
                                <td width="10%">
                                    <img src={position} alt='posizione' style={{ width: "1em" }} />
                                </td>
                                <td width="90%">
                                    <div className={[sensoreGeofencing.banner === 1 ? "bgYellow" : sensoreGeofencing.banner === 2 ? "bgRed" : ""] + " alarmG"}>
                                        &ensp;{sensoreGeofencing.stato === "Posizione sconosciuta" ? langSet.posizioneSconosciuta :
                                            sensoreGeofencing.stato === "In Corridoio" ? langSet.inCorridoio :
                                                sensoreGeofencing.stato === "In Camera" ? langSet.inCamera :
                                                    sensoreGeofencing.stato === "In guardiola" ? langSet.inGuardiola : sensoreGeofencing.stato}
                                        <div className="right">
                                            {sensoreGeofencing.banner === -1 ? <img src={offline} alt="alrm" /> : sensoreGeofencing.banner === 1 ? <img src={alarmY} alt="alrm" /> : sensoreGeofencing.banner === 2 ? <img src={alarmR} alt="alrm" /> : <div className='dot green' style={{ right: ".75em" }}>&nbsp;</div>}

                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={caduta} alt='caduta' style={{ width: "1em" }} />
                                </td>
                                <td>
                                    <div className={[sensoreCaduta.banner === 1 ? "bgYellow" : sensoreCaduta.banner === 2 ? "bgRed" : ""] + " alarmG"}>
                                        &ensp;{sensoreCaduta.stato === "Monitoraggio..." ? langSet.monitoraggio : sensoreCaduta.stato === "Caduta rilevata!" ? langSet.cadutaRilevata : langSet.monitoraggio}
                                        <div className="right">
                                            {sensoreCaduta.banner === -1 ? <img src={offline} alt="alrm" /> : sensoreCaduta.banner === 1 ? <img src={alarmY} alt="alrm" /> : sensoreCaduta.banner === 2 ? <img src={alarmR} alt="alrm" /> : <div className='dot green' style={{ right: ".75em" }}>&nbsp;</div>}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src={bed} alt='bed' style={{ width: "1em" }} />
                                </td>
                                <td>
                                    <div className={[sensoreLetto.banner === 1 ? "bgYellow" : sensoreLetto.banner === 2 ? "bgRed" : ""] + " alarmG"}>
                                        &ensp;{sensoreLetto.stato === "Assente" ? langSet.assente : sensoreLetto.stato === "Presente" ? langSet.presente : "Offline"}
                                        <div className="right">
                                            {sensoreLetto.banner === -1 ? <img src={offline} alt="alrm" /> : sensoreLetto.banner === 1 ? <img src={alarmY} alt="alrm" /> : sensoreLetto.banner === 2 ? <img src={alarmR} alt="alrm" /> : <div className='dot green' style={{ right: ".75em" }}>&nbsp;</div>}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <table style={{ marginTop: ".5em" }}>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <div className={[sensoreCaduta.banner === 1 ? "bgYellow" : sensoreCaduta.banner === 2 ? "bgRed" : ""] + " alarmG"}>
                                        &ensp;{sensoreCaduta.stato}
                                        <div className="right">
                                            {sensoreCaduta.banner === -1 ? <img src={offline} alt="alrm" /> : sensoreCaduta.banner === 1 ? <img src={alarmY} alt="alrm" /> : sensoreCaduta.banner === 2 ? <img src={alarmR} alt="alrm" /> : <div className='dot green' style={{ right: ".75em" }}>&nbsp;</div>}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>
                :
                <table id="Lista" onClick={() => props.openPopup("residente", props.residente)} style={{ cursor: "pointer" }}>
                    <tbody>
                        <tr>
                            <td width="11.25%" >
                                {props.residente.nome}
                            </td>
                            <td width="18.70%">
                                <div>{props.residente.percorso}</div>
                            </td>
                            <td width="17.25%">
                                <div className={[sensoreGeofencing.banner === 1 ? "bgYellow" : sensoreGeofencing.banner === 2 ? "bgRed" : ""] + " alarm"}>
                                    &ensp;{sensoreGeofencing.stato === "Posizione sconosciuta" ? langSet.posizioneSconosciuta :
                                        sensoreGeofencing.stato === "In Corridoio" ? langSet.inCorridoio :
                                            sensoreGeofencing.stato === "In Camera" ? langSet.inCamera :
                                                sensoreGeofencing.stato === "In guardiola" ? langSet.inGuardiola : sensoreGeofencing.stato}
                                    <div className="right">
                                        {sensoreGeofencing.banner === -1 ? <img src={offline} alt="alrm" /> : sensoreGeofencing.banner === 1 ? <img src={alarmY} alt="alrm" /> : sensoreGeofencing.banner === 2 ? <img src={alarmR} alt="alrm" /> : <div className='dot green' style={{ right: ".75em" }}>&nbsp;</div>}

                                    </div>
                                </div>
                            </td>
                            <td width="17.15%">
                                <div className={[sensoreCaduta.banner === 1 ? "bgYellow" : sensoreCaduta.banner === 2 ? "bgRed" : ""] + " alarm"}>
                                    &ensp;{sensoreCaduta.stato === "Monitoraggio..." ? langSet.monitoraggio : sensoreCaduta.stato === "Caduta rilevata!" ? langSet.cadutaRilevata : "Offline"}
                                    <div className="right">
                                        {sensoreCaduta.banner === -1 ? <img src={offline} alt="alrm" /> : sensoreCaduta.banner === 1 ? <img src={alarmY} alt="alrm" /> : sensoreCaduta.banner === 2 ? <img src={alarmR} alt="alrm" /> : <div className='dot green' style={{ right: ".75em" }}>&nbsp;</div>}
                                    </div>
                                </div>
                            </td>
                            <td width="17.25%">
                                <div className={[sensoreLetto.banner === 1 ? "bgYellow" : sensoreLetto.banner === 2 ? "bgRed" : ""] + " alarm"}>
                                    &ensp;{sensoreLetto.stato === "Assente" ? langSet.assente : sensoreLetto.stato === "Presente" ? langSet.presente : "Offline"}
                                    <div className="right">
                                        {sensoreLetto.banner === -1 ? <img src={offline} alt="alrm" /> : sensoreLetto.banner === 1 ? <img src={alarmY} alt="alrm" /> : sensoreLetto.banner === 2 ? <img src={alarmR} alt="alrm" /> : <div className='dot green' style={{ right: "1em" }}>&nbsp;</div>}
                                    </div>
                                </div>
                            </td>
                            {/* <td width="18.40%">
                                <div className={[sensoreCaduta.banner === 1 ? "bgYellow" : sensoreCaduta.banner === 2 ? "bgRed" : ""] + " alarm"}>
                                    &ensp;{sensoreCaduta.stato}
                                    <div className="right">
                                        {sensoreCaduta.banner === -1 ? <img src={offline} alt="alrm" /> : sensoreCaduta.banner === 1 ? <img src={alarmY} alt="alrm" /> : sensoreCaduta.banner === 2 ? <img src={alarmR} alt="alrm" /> : <div className='dot green' style={{ right: ".75em" }}>&nbsp;</div>}
                                    </div>
                                </div>
                            </td> */}
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
}

export default Residente;