import axios from 'axios';
import * as Magazzino from './magazzino_module.js';
import * as Crypto from '../crypt/crypt.js';
import * as Auth from '../auth/auth.js';

/*--------------------- OTTENGO IL MAGAZZINO DELLA STRUTTURA ---------------------*/
async function getMagazzinoStruttura(token, sort, orderby) {
    try {
        const headers = { 'Authorization': `Bearer ${token}` };

        let response = await axios.get(`${Auth.default.url}/magazzino/all`, { params: { sortby: sort, orderby: orderby }, headers });

        let sensori = JSON.parse(Crypto.decrypt(response.data));

        let data = [];

        for (let sensore of sensori) {
            data.push(new Magazzino.GetMagazzino(sensore));
        }
        return { result: true, data: data };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- AGGIUNGO SENSORE AL MAGAZZINO STRUTTURA ---------------------*/
async function addSensoreMagazzino(token, id, nome, tipo, modello) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const nuovoSensore = new Magazzino.AddSensore(modello, tipo, nome, id);

        const response = await axios.post(`${Auth.default.url}/magazzino`, Crypto.encrypt(JSON.stringify(nuovoSensore.toJson())), { headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- SPOSTO SENSORE DAL MAGAZZINO STRUTTURA A TABELLA SENSORI ---------------------*/
async function patchSensoreMagazzino(token, id_sensore) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.patch(`${Auth.default.url}/magazzino/${id_sensore}/sensori`, {}, { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

/*--------------------- ELIMINO SENSORE DAL MAGAZZINO ---------------------*/
async function deleteSensoreMagazzino(token, id_sensore) {
    try {
        const headers = { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain' };

        const response = await axios.delete(`${Auth.default.url}/magazzino/${id_sensore}`, { headers: headers });

        return { result: true, message: Crypto.decrypt(response.data) };
    } catch (e) {
        if (axios.AxiosError && e.response) {
            return { result: false, error: Crypto.decrypt(e.response.data) };
        } else {
            return { result: false, error: e.toString() };
        }
    }
}

export default { getMagazzinoStruttura, addSensoreMagazzino, patchSensoreMagazzino, deleteSensoreMagazzino };