
/*--------------------- CLASSE OTTENGO LISTA SENSORI ---------------------*/
class GetSensoriAll {
    constructor(json) {
        this.id = json.id;
        this.nome = json.nome;
        this.tipo = json.tipo;
        this.stato = json.stato;
        this.percorso = json.percorso;
        this.batteria =  json.batteria;
        this.alarms = json.alarms;
        this.timestamp = json.timestamp;
    }
}

/*--------------------- CLASSE CAMBIO NOME SENSORE ---------------------*/
class PatchNomeSensore {
    constructor(nome) {
        this.nome = nome;
    }
    toJson() {
        return {
            nome: this.nome
        }
    }
}

export { GetSensoriAll, PatchNomeSensore };