

class AddCorridoio {
    constructor(id, descrizione, id_thinkin, id_ala) {
        this.id = id;
        this.descrizione = descrizione;
        this.id_thinkin = id_thinkin;
        this.id_ala = id_ala;
    }

    toJson() {
        return {
            id: this.id,
            descrizione: this.descrizione,
            id_thinkin: this.id_thinkin,
            id_ala: this.id_ala
        }
    }
}

export { AddCorridoio };